import React, {useEffect} from "react";
import './TopBarMenuList.css'
import module from '../../../../constants/TopBarConstants'
import TopBarMenuElementComponent from "./TopBarMenuElement/TopBarMenuElementComponent";
import {getArray, getObject} from "../../../../utils/RestApi";
import ContactsListComponent from "../ContactsList/ContactsListComponent";

function TopBarMenuListComponent(props) {

    let [items, setItems] = React.useState([]);

    useEffect(() => {
        getArray('/api/menu', setItems)
    }, [])

    function showItem(item) {
        return (
                <TopBarMenuElementComponent
                    title={item.title}
                    route={item.route}
                    menu={item.menu? item.menu : undefined}
                    hasDropDown={!!item.menu}
                    style={item.style? item.style : undefined}
                    download={item.download? item.download : undefined}
                    downloadTitle={item.downloadTitle? item.downloadTitle : undefined}
                />
        )
    }


    function showItems(items) {
        return items.map(item => <> {showItem(item)} </>);
    }



    return (
        <ul className={'top-bar-menu-list'}>
            <div className={'top-bar-menu-items'}>
                {showItems(items)}
            </div>
        </ul>
    )
}

TopBarMenuListComponent.propTypes = {}

export default TopBarMenuListComponent;
