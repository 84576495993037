import commonModule from "../constants/CommonConstants";


export const getObject = async (url, handler) => {
    try {
        const response = await fetch(commonModule.SITE_URL + url);
        if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
        } else {
            let obj = JSON.parse(await response.json());

            handler(obj);
        }
    } catch (error) {
        console.error('Error fetching object:', error);
    }
};


export const getArray = async (url, handler) => {
    try {
        const response = await fetch(commonModule.SITE_URL + url);
        if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
        } else {
            let array = JSON.parse(await response.json());

            handler(array);
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
};
