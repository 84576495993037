import React, {useEffect, useRef, useState} from "react";
import './Teaser.css'
import TeaserBuyButtonComponent from "./TeaserBuyButton/TeaserBuyButtonComponent";
import AdvantagesListComponent from "./AdvantagesList/AdvantagesListComponent";
import ModalBluePulseButtonComponent
    from "../../../instances/buttons/ModalBluePulseButton/ModalBluePulseButtonComponent";
import {getObject} from "../../../utils/RestApi";

function TeaserComponent(props) {
    const videoRef = useRef();


    const [isAnimated, setIsAnimated] = useState(false);
    const [isShowAdvantages, setIsShowAdvantages] = useState(false);
    const [teaserInstance, setTeaserInstance] = useState({});


    useEffect(() => {
        getObject('/api/teaser_instance', setTeaserInstance)
        setIsAnimated(true); // Начинаем анимацию после монтирования компонента
        setTimeout(() => {
            setIsShowAdvantages(true);
        }, 10000);
    }, [])

    function showVideo() {
        return (
            <video autoPlay muted loop style={{
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                objectFit: 'cover',
                position: 'absolute',
                opacity: 0.2
            }}>
                <source src="/assets/video/teaser.mp4"
                        type="video/mp4"/>
            </video>
        )
    }

    function showImageBackground() {
        return (
            <img src={'/assets/teaser/mobile_back.png'}
                 style={{
                     width: '100%',
                     height: '100%',
                     top: '0',
                     left: '0',
                     objectFit: 'cover',
                     position: 'absolute',
                     opacity: 0.2
                 }}
            >

            </img>
        )
    }

    function showAdvantages() {

        return (
            <div className={'teaser-advantages'}>
                <AdvantagesListComponent/>
            </div>
        )
    }

    function showText() {
        if(teaserInstance.title && teaserInstance.big_title) {
            return (
                <div className={'teaser-text-outer  ${isAnimated? \'animated\' : \'\'}'}>
                    <div className={'teaser-text-inner'}>
                        <div className={'teaser-text-body'}>
                            <p className={'teaser-text-mini'}>{teaserInstance.title}</p>
                            <p className={'teaser-text'}>{teaserInstance.big_title}</p>
                            {showAdvantages()}
                        </div>

                        <div className={'mobile-teaser-text-body'}>
                            <p className={'mobile-teaser-text'}>{teaserInstance.title + ' ' + teaserInstance.big_title}</p>
                            {showAdvantages()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }

    }

    return (
        <div className={'teaser'}>
            <div className={'teaser-pc-version'}>
                {showVideo()}
            </div>
            <div className={'teaser-mobile-version'}>
                {showImageBackground()}
            </div>
            {showText()}
        </div>
    )
}

TeaserComponent.propTypes = {}

export default TeaserComponent
