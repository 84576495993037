// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-map {
    margin-top: 30px;
}


.tooltip {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.29);
    z-index: 100000;
    padding: 10px;
    margin-left: 20px;
    margin-top: -120px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tooltip-title {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.tooltip-description {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
}

.tooltip-icon {
    height: 78px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 850px) {
    .contacts-map {
        display: block;
    }

    .mobile-contacts-map {
        display: none;
    }
}


@media (max-width: 850px) {
    .contacts-map {
        display: none;
    }

    .mobile-contacts-map {
        margin-top: 30px;
        display: block;
        margin-left: 6vw;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/contacts/ContactsMap/ContactsMap.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;;AAGA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,4CAA4C;IAC5C,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,aAAa;IACjB;AACJ;;;AAGA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;QAChB,cAAc;QACd,gBAAgB;IACpB;AACJ","sourcesContent":[".contacts-map {\n    margin-top: 30px;\n}\n\n\n.tooltip {\n    width: fit-content;\n    height: fit-content;\n    background-color: white;\n    border-radius: 15px;\n    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.29);\n    z-index: 100000;\n    padding: 10px;\n    margin-left: 20px;\n    margin-top: -120px;\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n.tooltip-title {\n    font-family: Roboto, sans-serif;\n    font-size: 18px;\n    font-weight: bold;\n}\n\n.tooltip-description {\n    font-family: Roboto, sans-serif;\n    font-size: 16px;\n    font-weight: normal;\n    display: inline-block;\n}\n\n.tooltip-icon {\n    height: 78px;\n    display: inline-block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n@media (min-width: 850px) {\n    .contacts-map {\n        display: block;\n    }\n\n    .mobile-contacts-map {\n        display: none;\n    }\n}\n\n\n@media (max-width: 850px) {\n    .contacts-map {\n        display: none;\n    }\n\n    .mobile-contacts-map {\n        margin-top: 30px;\n        display: block;\n        margin-left: 6vw;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
