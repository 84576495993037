import React from "react";
import './RedArrowButton.css'
import PropTypes from "prop-types";


function RedArrowButtonComponent(props) {

    function showTitle() {
        return (
            <span className={'red-arrow-button-title'}>{props.title}</span>
        )
    }

    function showArrow() {
        return (
            <div>
                <img src={'/assets/buttons/arrow.svg'}></img>
            </div>
        )
    }

    function getCustomHorizontalPadding() {
        if(props.customHorizontalPadding) {
            return props.customHorizontalPadding;
        } else {
            return 40
        }
    }

    return (
        <a className={'button red-arrow-button'} style={{
            padding: '16px '+ getCustomHorizontalPadding() + 'px'
        }}>
            {showTitle()}
            {showArrow()}
        </a>
    )
}

RedArrowButtonComponent.propTypes = {
    title: PropTypes.string,
    customHorizontalPadding: PropTypes.number
}

export default RedArrowButtonComponent;
