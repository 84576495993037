// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
    width: 100%;
    min-height: 100vh;
    background-color: #1A1D23;
    padding-bottom: 100px;
    position: relative;
}

.contacts-address-format {
    text-align: left;
}

.contacts-content {
    padding-top: 200px;
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.contacts-middle-content {
    width: 1140px;
    position: relative;
}

.contacts-left-content {
    width: 47%;
    float: left;
}

.contacts-right-content {
    width: 47%;
    float: right;
}

.contacts-tooltip-text {
    margin-top: 50px;
}

@media (min-width: 1180px) {
    .contacts-mobile-content {
        display: none;
    }

    .contacts-middle-content {
        display: block;
    }


}


@media (max-width: 1180px) {

    .contacts-address-text {
        margin-top: 30px;
    }

    .contacts-mobile-content {
        display: block;
    }

    .contacts-middle-content {
        display: none;
    }

    .contacts-content {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .contacts {
        padding-bottom: 0;
    }

}


@media (max-width: 408px) {
    .contacts-address-text {
        margin-top: 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/contacts/ContactsContent/ContactsContent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;;AAGJ;;;AAGA;;IAEI;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;AAEJ;;;AAGA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".contacts {\n    width: 100%;\n    min-height: 100vh;\n    background-color: #1A1D23;\n    padding-bottom: 100px;\n    position: relative;\n}\n\n.contacts-address-format {\n    text-align: left;\n}\n\n.contacts-content {\n    padding-top: 200px;\n    width: 1140px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.contacts-middle-content {\n    width: 1140px;\n    position: relative;\n}\n\n.contacts-left-content {\n    width: 47%;\n    float: left;\n}\n\n.contacts-right-content {\n    width: 47%;\n    float: right;\n}\n\n.contacts-tooltip-text {\n    margin-top: 50px;\n}\n\n@media (min-width: 1180px) {\n    .contacts-mobile-content {\n        display: none;\n    }\n\n    .contacts-middle-content {\n        display: block;\n    }\n\n\n}\n\n\n@media (max-width: 1180px) {\n\n    .contacts-address-text {\n        margin-top: 30px;\n    }\n\n    .contacts-mobile-content {\n        display: block;\n    }\n\n    .contacts-middle-content {\n        display: none;\n    }\n\n    .contacts-content {\n        width: 100%;\n    }\n}\n\n@media (max-width: 850px) {\n    .contacts {\n        padding-bottom: 0;\n    }\n\n}\n\n\n@media (max-width: 408px) {\n    .contacts-address-text {\n        margin-top: 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
