// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    width: 100%;
    min-height: 100vh;
    background-color: #1A1D23;
    padding-bottom: 100px;
    position: relative;
}

.about-content {
    padding-top: 200px;
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    position: relative;
}

@media (max-width: 1160px) {

    .about-content {
        width: 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/about/AboutContent/AboutContent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;;AAEA;;IAEI;QACI,WAAW;IACf;AACJ","sourcesContent":[".about {\n    width: 100%;\n    min-height: 100vh;\n    background-color: #1A1D23;\n    padding-bottom: 100px;\n    position: relative;\n}\n\n.about-content {\n    padding-top: 200px;\n    width: 1140px;\n    margin-left: auto;\n    margin-right: auto;\n    z-index: 10;\n    position: relative;\n}\n\n@media (max-width: 1160px) {\n\n    .about-content {\n        width: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
