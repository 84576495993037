import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import './ContactsMap.css'
import { Placemark, Map, YMaps } from "@pbe/react-yandex-maps";
import { useYMaps } from "@pbe/react-yandex-maps";

function ContactsMapComponent(props) {
    if(props.gps && props.address && props.workTime) {
        return (
            <YMaps>
                <ContactsMapChildComponent
                    gps={props.gps}
                    address={props.address}
                    workTime={props.workTime}
                />
            </YMaps>
        );
    } else {
        return (
            <></>
        )
    }
}


function ContactsMapChildComponent(props) {
    const [tooltipElements, setTooltipElements] = useState([]);
    const wasCalled = useRef(false);
    const ymaps = useYMaps(); // Получаем экземпляр ymaps

    function interactTooltip() {

    }

    useLayoutEffect(() => {
        if (ymaps && !wasCalled.current) {
            ymaps.load().then(() => {
                wasCalled.current = true;
                // Поиск элементов с определенной частью имени класса и заданными стилями

                setTimeout(() => {
                    const elements = document.querySelectorAll('*');
                    const matchingElements = Array.from(elements).filter(el => {
                        const classes = el.className.split(/\s+/);
                        return classes.some(cls => cls.includes('islets_icon-caption'))
                            && el.style.padding === '0px'
                            && el.style.maxWidth === '0px';
                    });

                    // Создание подсказок для найденных элементов
                    matchingElements.forEach(el => {
                        const parent = el.parentElement;
                        if (parent) {
                            const tooltips = document.getElementsByClassName('tooltip');
                            const count = tooltips.length;
                            if (count < 4) {
                                const tooltipDiv = document.createElement('div');
                                tooltipDiv.className = 'tooltip';

                                /*

                                const addressTitle = document.createElement('p');
                                addressTitle.className = 'tooltip-title';
                                addressTitle.textContent = 'Адрес:'
                                tooltipDiv.appendChild(addressTitle);

                                const addressDescription = document.createElement('p');
                                addressDescription.className = 'tooltip-description';
                                addressDescription.textContent = props.address
                                tooltipDiv.appendChild(addressDescription);

                                const workTimeTitle = document.createElement('p');
                                workTimeTitle.className = 'tooltip-title';
                                workTimeTitle.textContent = 'График работы:'
                                tooltipDiv.appendChild(workTimeTitle);

                                const workTimeDescription = document.createElement('p');
                                workTimeDescription.className = 'tooltip-description';
                                workTimeDescription.textContent = props.workTime
                                tooltipDiv.appendChild(workTimeDescription);

                                 */

                                const icon = document.createElement("img");
                                icon.className = 'tooltip-icon';
                                icon.src = '/assets/topbar/black-words-russian-everest-logo.svg';
                                tooltipDiv.appendChild(icon);

                                parent.insertAdjacentElement('afterend', tooltipDiv);

                                parent.addEventListener('click', interactTooltip);

                            }
                        }
                    });

                    setTooltipElements(matchingElements);
                }, 100)
            });
        }
    }, [ymaps]);

    function showTooltip() {
        return null; // Не рендерим компонент Tooltip, так как подсказки создаются напрямую в DOM
    }

    function showContactsMap() {
        return (
            <div className={'contacts-map'}>
                <Map defaultState={{ center: [props.gps.x, props.gps.y], zoom: 18 }} width={"100%"} height={"541px"}>
                    <Placemark geometry={[props.gps.x, props.gps.y]} />
                </Map>
            </div>
        )
    }

    function showMobileContactsMap() {
        return (
            <div className={'mobile-contacts-map'}>
                <Map defaultState={{ center: [props.gps.x, props.gps.y], zoom: 18 }} width={"88vw"} height={"88vw"}>
                    <Placemark geometry={[props.gps.x, props.gps.y]} />
                </Map>
            </div>
        )
    }

    return (
        <>
            {showContactsMap()}
            {showMobileContactsMap()}
        </>
    );
}

ContactsMapComponent.propTypes = {
    gps: PropTypes.any,
    address: PropTypes.string,
    workTime: PropTypes.string
}

ContactsMapChildComponent.propTypes = {
    gps: PropTypes.any,
    address: PropTypes.string,
    workTime: PropTypes.string
}

export default ContactsMapComponent;
