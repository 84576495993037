import React, {useEffect, useRef, useState} from 'react';
import PhoneInput from 'react-phone-number-input/input'
import './ModalForm.css'
import PropTypes from "prop-types";
import ModalFormToastComponent from "../ModalFormToast/ModalFormToastComponent";
import BluePulseButtonComponent from "../../buttons/BluePulseButton/BluePulseButtonComponent";
import emailjs from '@emailjs/browser';


function ModalFormComponent(props) {
    const ref = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [formPhoneNumber, setFormPhoneNumber] = useState('')
    let [formParams, setFormParams] = useState({
        contact: '',
        mail: '',
        text: '',
    })
    const [formFile, setFormFile] = useState(null)
    const [firstTime, setFirstTime] = useState(true)
    const [duration, setDuration] = useState(0)
    const [reactor, setReactor] = useState(false)


    const sendForm = () => {
        setFirstTime(false)
        if(Object.values(formParams).every(value =>
            value !== undefined &&
            value !== null &&
            value !== ''
        ) && formPhoneNumber !== undefined && formPhoneNumber !== null && formPhoneNumber !== ''&& formPhoneNumber !== '+7') {
            sendEmail()
            closeModal()
        }
    }



    const sendEmail = () => {
        emailjs.send('autoeverestsnab', 'default_template', formParams, 'VGQzcLCZHjebs1hSu')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.log('FAILED...', error);
            });

        setDuration(5000)
        setReactor(!reactor)
    };

    const setFormVariableValue = (variableName, value) => {
        setFormParams(prevParams => ({
            ...prevParams,
            [variableName]: value
        }));
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closeModal()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    function closeModal() {
        props.handleClose()
    }

    function getClassNameParamInput(variableName) {
        if ((formParams[variableName].length === 0) && !firstTime)  {
            return 'red-modal-param-input'
        } else {
            return 'modal-param-input'
        }
    }

    function showRedSubscription(variableName) {
        if ((formParams[variableName].length === 0) && !firstTime) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./assets/other/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showRedSubscriptionValue(value) {
        if (value && !firstTime) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./assets/other/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showRedSubscriptionPhoneValue(value) {
        if ((value && value === '+7' || !value) && !firstTime) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./assets/other/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showDefaultInput(title, variableName) {
        return (
            <div className={'modal-param-space'}>
                <p className={'modal-param-title'}>{title}</p>
                <input
                    className={getClassNameParamInput(variableName)}
                    onChange={(e) => {
                        setFormVariableValue(variableName, e.target.value)
                    }}
                />
                {showRedSubscription(variableName)}
            </div>
        )
    }

    function showTextAreaInput(title, variableName) {
        return (
            <div className={'modal-param-space'}>
                <p className={'modal-param-title'}>{title}</p>
                <textarea
                    className={getClassNameParamInput(variableName)}
                    onChange={(e) => {
                        setFormVariableValue(variableName, e.target.value)
                    }}
                />
                {showRedSubscription(variableName)}
            </div>
        )
    }

    function showPhoneInput(title, variableName) {
        return (
            <div className={'modal-param-space'}>
                <p className={'modal-param-title'}>{title}</p>
                <PhoneInput
                    country="RU"
                    value={formPhoneNumber}
                    onChange={setFormPhoneNumber}
                    international={true}
                    withCountryCallingCode={true}
                />
                {showRedSubscriptionPhoneValue(formPhoneNumber)}
            </div>
        )
    }
    useEffect(() => {
        setFormVariableValue('contact', formPhoneNumber)
    }, [formPhoneNumber])

    function showFileInput(title, variableName) {
        return (
            <div>
                <div className={'modal-param-space'}>
                    <p className={'modal-param-title'}>{title}</p>

                    <div className='background-input' aria-disabled="false">
                        {showStatus()}
                        <input
                            className={'input-file'}
                            type="file"
                            accept='.jpg, .png'
                            onChange={(e)=>{
                                setFormFile(e.target.files[0])
                                setIsLoaded(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function showStatus() {
        if(!isLoaded) {
            return (
                <div className="before-upload-field">
                    <img src="./images/common/upload.svg"/>
                    <div className="select-a-file">
                        <p className="select-a-file-text">Выберите файл</p>
                        <p className="select-a-file-text-black">или переместите сюда</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="after-upload-field">
                    <img src="./images/common/ok.svg"/>
                </div>
            )
        }
    }

    function showModalContent() {
        return (
            <>
                {showDefaultInput('Электронная почта', 'mail')}
                {showPhoneInput('Телефон', 'phone')}
                {showTextAreaInput('Текст', 'text')}
            </>
        )
    }


    function showModal() {
        let active = props.active ? 'active' : 'nonactive'
        return (
            <div className={'modal-component'}>
                <div className={'modal-overlay ' + active}></div>
                <div className={'modal ' + active} ref={ref}>
                    <div className={'modal-header'}>
                        <div className={'modal-title'}>Связаться с нами</div>
                        <img src={'/assets/other/cancel.svg'} className={'cancel-button'} onClick={() => {
                            closeModal()
                        }}></img>
                    </div>
                    <div className={'upper-modal-divider'}></div>
                    <div className={'modal-content'}>
                        {showModalContent()}
                    </div>
                    <div className={'under-modal-divider'}></div>

                    <div className={'modal-button'}>
                        <BluePulseButtonComponent
                            title={'Отправить'}
                            handler={() => {sendForm()}}
                        />
                    </div>



                </div>
            </div>
        )
    }

    return (
        <>
            {showModal()}
            <ModalFormToastComponent
                title={'Отправлено'}
                description="Дождитесь ответа в ближайшее время"
                duration={duration}
                reactor={reactor}
            />
        </>
    );
}


ModalFormComponent.propTypes = {
    active: PropTypes.bool,
    handleClose: PropTypes.any
};


export default ModalFormComponent;
