// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar-menu-item {
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
    font-weight: 300;
    font-style: normal;
    font-size: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.71);
}

.top-bar-menu-item:hover {
    color: #FFFFFF;
    transition: all, 200ms;
}

.drop-down {
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.34);
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    padding-left: 12px;
    padding-right: 12px;

    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    transition: all 0.3s;
    z-index: 11000;
    padding-top: 10px;
    padding-bottom: 10px;
}

.drop-down-element {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.71);
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 0;
}

.drop-down-show-all {
    font-family: "Fira Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #039AD2;
}

.drop-down-element:hover {
    color: #FFFFFF;
    transition: all, 200ms;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/TopBar/TopBarMenuList/TopBarMenuElement/TopBarMenuElement.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,qCAAqC;IACrC,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;;IAEnB,mCAAmC;IACnC,2BAA2B;IAC3B,oBAAoB;IACpB,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC;IAChC,iBAAiB;IACjB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":[".top-bar-menu-item {\n    display: inline-block;\n    margin-right: 15px;\n    margin-left: 15px;\n    font-weight: 300;\n    font-style: normal;\n    font-size: 20px;\n    cursor: pointer;\n    color: rgba(255, 255, 255, 0.71);\n}\n\n.top-bar-menu-item:hover {\n    color: #FFFFFF;\n    transition: all, 200ms;\n}\n\n.drop-down {\n    margin-top: 10px;\n    background-color: rgba(0, 0, 0, 0.34);\n    width: fit-content;\n    position: absolute;\n    padding-left: 12px;\n    padding-right: 12px;\n\n    -webkit-backdrop-filter: blur(40px);\n    backdrop-filter: blur(40px);\n    transition: all 0.3s;\n    z-index: 11000;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.drop-down-element {\n    font-size: 18px;\n    font-weight: 300;\n    font-style: normal;\n    text-align: left;\n    color: rgba(255, 255, 255, 0.71);\n    line-height: 40px;\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\n.drop-down-show-all {\n    font-family: \"Fira Sans\", sans-serif;\n    font-size: 12px;\n    font-weight: normal;\n    text-align: left;\n    color: #039AD2;\n}\n\n.drop-down-element:hover {\n    color: #FFFFFF;\n    transition: all, 200ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
