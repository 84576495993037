let ACTIONS_LIST_ARRAY = [
    {
        image: '8711ae67-07f2-450f-b7fc-cb35b36e4197.svg',
        title: 'ПРОБЕЙ ДНО',
        description: 'Наши профессиональные металообрабатывающие инструменты обеспечивают точность и эффективность ваших работ.'
    }
]

let BREAD_CRUMBS_LIST_ARRAY = [
    {
        title: 'Главная страница',
        route: '/'
    }, {
        title: 'Акции',
        route: undefined
    }
]


let module = {
    ACTIONS_LIST_ARRAY: ACTIONS_LIST_ARRAY,
    BREAD_CRUMBS_LIST_ARRAY: BREAD_CRUMBS_LIST_ARRAY
}

export default module;
