import React, { useState, useEffect, useRef } from 'react';
import './PartnersLink.css';
import {getArray} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";
import { useWindowSize } from 'react-use';





function PartnersLinkComponent(props) {
    const partnersListRef = useRef(null);
    const [startAnimation, setStartAnimation] = useState(false);
    const [startScrollAnimation, setStartScrollAnimation] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0); // Добавляем состояние для хранения позиции скролла
    let [partners, setPartners] = useState([])
    let [handledPartners, setHandledPartners] = useState([])
    let [timer, setTimer] = useState(-1)
    let [timerFlag, setTimerFlag] = useState(false)
    const { width, height } = useWindowSize();

    useEffect(() => {
        setTimeout(() => {

            if(timer > -1) {
                setTimer(timer - 1)
                if(timer === 0) {
                    setStartScrollAnimation(true)
                }
            }

        }, 1000)
    }, [timer])

    useEffect(() => {
        setTimer(-2)
        getArray('/api/partners', setPartners)
    }, [])

    useEffect(() => {
        if(partners.length >= 4) {
            setHandledPartners(partners.concat(partners, partners))
        } else {

            if(290*partners.length > width) {
                setHandledPartners(partners.concat(partners, partners))
            } else {
                setHandledPartners(partners)
            }
        }
    }, [partners]);


    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setStartAnimation(true);
                observer.disconnect();
            }
        });

        if (partnersListRef.current) {
            observer.observe(partnersListRef.current);
        }

        return () => {
            if (partnersListRef.current) {
                observer.unobserve(partnersListRef.current);
            }
        };
    }, []);

    useEffect(() => {
        let intervalId;
        if (startScrollAnimation) {
            intervalId = setInterval(() => {
                partnersListRef.current?.scrollBy(1, 0, 1); // Скорость прокрутки 5px в 50мс
            }, 8);
        } else {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [startScrollAnimation]);

    const handleScrollAnimation = (event) => {
        if(startScrollAnimation === false) {

        }
    };

    function showPartner(partner) {
        return (
            <div className={'partner'}>
                <div className={'partner-image-outer'}>
                    <img className={'partner-image-inner'} src={commonModule.SITE_URL + '/media/partners/'+ partner.image}/>
                </div>
            </div>
        );
    }

    function showPartners(partners) {
        return partners.map(partner => <>{showPartner(partner)}</>);
    }

    function showDust() {
        return (
            <>
                <img src={'./assets/partners-link/dust.png'} className={'partners-link-dust'} />


            </>
        );
    }

    function showTitle() {
        return (
            <p className={'partners-link-title'}>НАШИ ПАРТНЕРЫ</p>
        );
    }

    const animationClass = startAnimation ? 'slideInBottom' : '';


    setInterval(() => {

        const scrollStatus = partnersListRef.current?.scrollLeft;


        if (scrollStatus === 0) {
            partnersListRef.current?.scrollTo(290 * partners.length, 0);

            const partnerList = document.getElementsByClassName('partners-list')[0];
            partnerList.className = 'partners-list partners-list-not-active'
            setTimeout(() => {
                partnerList.className = 'partners-list'
            }, 1)
        }

        if (partners.length > 0 && (scrollStatus >= 290*(partners.length*2))) {
            partnersListRef.current?.scrollTo(290 * partners.length, 0);

            const partnerList = document.getElementsByClassName('partners-list')[0];
            partnerList.className = 'partners-list partners-list-not-active'
            setTimeout(() => {
                partnerList.className = 'partners-list'
            }, 10)
        }
    }, 1)

    useEffect(() => {
        const handleMouseEnter = () => {
            // Проверяем ширину экрана перед добавлением класса
            if (window.innerWidth > 1100) {
                document.body.classList.add('block-scroll');
            }
        };

        const handleMouseLeave = () => {
            // Проверяем ширину экрана перед удалением класса
            if (window.innerWidth > 1100) {
                document.body.classList.remove('block-scroll');
            }
        };

        const appNode = partnersListRef.current;

        if (appNode) {
            appNode.addEventListener('mouseenter', handleMouseEnter);
            appNode.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (appNode) {
                appNode.removeEventListener('mouseenter', handleMouseEnter);
                appNode.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    const handleWheel = (event) => {
        setStartScrollAnimation(false)
        setTimer(10)
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)));
        event.currentTarget.scrollLeft += (delta * 50); // Измените значение 50 на нужную скорость прокрутки
        event.preventDefault();
    };

    const handleTouchEnd = (event) => {
        setStartScrollAnimation(false)
        setTimer(10)
    }

    return (
        <div className={'partners-link'}>
            {showDust()}
            {showTitle()}
            <div style={{zIndex: 3}}>
                <div className={'div-gradients'}>
                    <div className={'div-gradient-left'}></div>
                    <div className={'div-gradient-right'}></div>
                </div>
                <div className={'partners-list'}
                     ref={partnersListRef}
                     style={{animationName: animationClass}}
                     onWheel={handleWheel}
                     onTouchEnd={handleTouchEnd}
                >
                    {showPartners(handledPartners)}
                </div>

            </div>
        </div>
    );
}

export default PartnersLinkComponent;
