import TopBarComponent from "../components/common/TopBar/TopBarComponent";
import FooterComponent from "../components/common/Footer/FooterComponent";
import DeliveryContentComponent from "../components/delivery/DeliveryContent/DeliveryContentComponent";


function DeliveryPage() {
    return (
        <div className="App">
            <header className="App-header">
                <TopBarComponent
                    morphScrollY={0}
                />
                <DeliveryContentComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default DeliveryPage;