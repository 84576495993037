import React, {useEffect} from "react";
import '../../common/Common.css'
import './ActionsContent.css'
import module from "../../../constants/ActionsConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import {useParams} from "react-router-dom";
import {getArray} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";
import {GetTextWithBoldParts} from "../../../utils/TextFunctions";

function ActionsContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);
    let [actions, setActions] = React.useState([]);

    const { id } = useParams();

    useEffect(() => {
        getArray('/api/actions', setActions)
    }, [])

    function showAction(action) {
        return (
            <div className={'action'}>
                <div className={'action-text'}>
                    <p className={'action-title'}>{action.title}</p>

                    <div className={'action-description'}>
                        {GetTextWithBoldParts(action.description)}
                    </div>
                </div>
                <div className={'action-image-outer'}>
                    <img
                        className={'action-image-inner'}
                        src={commonModule.SITE_URL + '/media/actions/'+action.image}
                        onError={(e) => { e.target.src = commonModule.SITE_URL + '/media/actions/default.svg'; }}
                    />
                </div>
            </div>
        )
    }

    function showActions(actions) {
        return actions.map(action => <> {showAction(action)} </>);
    }

    function showDust() {
        return <img src={'/assets/actions/dust.svg'} className={'left-bigger-dust'} />;
    }

    function showTitle() {
        return (
            <p className={'actions-title'}>АКЦИИ</p>
        )
    }

    return (
        <div className={'actions'}>
            {showDust()}
            <div className={'actions-content'}>
                <BreadCrumbsComponent
                    breadCrumbs={breadCrumbs}
                />
                {showTitle()}
                <div className={'actions-list'}>
                    {showActions(actions)}
                </div>
            </div>
        </div>
    )


}

ActionsContentComponent.propTypes = {

}

export default ActionsContentComponent
