import React, {useEffect, useRef, useState} from "react";
import './TopBarMenuElement.css'
import PropTypes from "prop-types";
import {downloadFromUrl} from "../../../../../utils/DownloadFunctions";


let menu = [
    //TODO from database CATALOG
    {
        title: 'Электроинструмент',
        id: '0f1882e0-33c9-45aa-b92e-4f4814a23250'
    }, {
        title: 'Ручной инструмент',
        id: 'f0ceb429-03de-4803-96e1-f773eb4dba40'
    }, {
        title: 'Станки',
        id: '0db5bdf4-2d77-43cd-a254-42e23b1a6817'
    }, {
        title: 'Садовая техника',
        id: 'ac05b1ed-bec3-412d-9273-1a6d1db22660'
    }, {
        title: 'Садовая техника',
        id: 'ac05b1ed-bec3-412d-9273-1a6d1db22660'
    }, {
        title: 'Садовая техника',
        id: 'ac05b1ed-bec3-412d-9273-1a6d1db22660'
    }
]

function TopBarMenuElementComponent(props) {

    let [clicked, setClicked] = useState(false)
    let maxElementsNumber = 5

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current &&!ref.current.contains(event.target)) {
                setClicked(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const navigateToPage = (route) => {
        window.location.href = route;
    };

    function showDropDownShowAll(elements) {

        if(elements.length > maxElementsNumber) {
            return (
                <p className={'drop-down-show-all'}>Показать всё</p>
            )
        } else {
            return (
                <></>
            )
        }

    }

    function showDropDownElement(element) {
        return (
            <p className={'drop-down-element'} onClick={()=>{navigateToPage(element.route)}}>{element.title}</p>
        )
    }

    function showDropDownElements(elements) {
        elements = elements.slice(0, maxElementsNumber);
        return elements.map(element => <> {showDropDownElement(element)} </>);
    }

    function showDropDown(elements) {
        if(elements) {
            return (
                <div ref={ref} className={'drop-down'} hidden={!clicked}>
                    {showDropDownElements(elements)}
                    {showDropDownShowAll(elements)}
                </div>
            )
        } else {
            return (
                <></>
            )
        }

    }

    function animate() {
        let dropDowns = document.getElementsByClassName('drop-down')
        if(dropDowns && dropDowns.length) {
            for (const dropDown of dropDowns) {
                if(!clicked) {
                    dropDown.style.height = '0px'
                } else {
                    dropDown.style.height = '0px'
                    setTimeout(()=>{
                        dropDown.style.height = 'fit-content'
                    }, 100)
                }
            }
        }
    }

    useEffect(() => {
        //animate()
    }, [clicked])

    function onClick() {
        if(props.hasDropDown) {
            setClicked(!clicked)
        } else {
            if(props.route) {
                navigateToPage(props.route)
            } else {
                if(props.download && props.downloadTitle) {
                    downloadFromUrl(props.download, props.downloadTitle, 'application/octet-stream')
                }
            }
        }
    }

    return (
        <li
            className={'top-bar-menu-item'}
            onClick={()=>{onClick()}}
            style={props.style ? props.style : {}}
        >
            <a>
                {props.title}
            </a>
            {showDropDown(props.menu)}
        </li>
    )
}

TopBarMenuElementComponent.propTypes = {
    title: PropTypes.string,
    hasDropDown: PropTypes.bool,
    route: PropTypes.string,
    style: PropTypes.any,
    menu: PropTypes.array,
    download: PropTypes.string,
    downloadTitle: PropTypes.string
}

export default TopBarMenuElementComponent
