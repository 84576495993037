import React, { useEffect } from 'react';
import './ContactsContent.css'
import '../../common/Common.css'
import module from "../../../constants/ContactsConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import {getHrefFromAction, GetTextWithBoldParts} from "../../../utils/TextFunctions";
import ContactsPage from "../../../pages/ContactsPage";
import ContactsMapComponent from "../ContactsMap/ContactsMapComponent";
import DefaultTextProperty from "../../../instances/text-properties/DefaultTextProperty/DefaultTextProperty";
import ContactsFormComponent from "../ContactsForm/ContactsFormComponent";
import {getArray, getObject} from "../../../utils/RestApi";


let constantContactInfo = {
    'description': 'Телефон: <b>+7 981 830 38 33</b><br>Почта: <b>zakoz@everestsnab.ru</b><br>Telegram: <b>@everestsnab</b><br>',
    'address': 'г. Санкт-Петербург, ул. Полевая, д.123',
    'gps': {
        'x': 60.001829,
        'y': 30.149060
    }
}


function ContactsContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);
    let [contactInfo, setContactInfo] = React.useState([])

    useEffect(() => {
        getObject('/api/contact_info', setContactInfo)
    }, [])


    function showDust() {
        return <img src={'/assets/contacts/dust.svg'} className={'right-top-dust'} />;
    }

    function showWorkTime() {
        return (
            <div className={'page-gray-element'}>

                <div className={'page-gray-text-property'}>
                    {GetTextWithBoldParts('График работы: <b>' + contactInfo.work_time + '</b>')}
                </div>
            </div>
        )
    }

    function showContact(contact) {
        return (
            <div className={'page-gray-element'}>
                <p className={'page-gray-text-property'}>{contact.title + ':\u00A0'}</p>
                <a
                    className={'page-gray-text-value'}
                    href={getHrefFromAction(contact.action, contact.text)}
                    style={{ textDecoration: 'none', color: 'white' }}
                    target={contact.action.startsWith('link:') ? '_blank' : undefined}
                >{contact.text}</a>
            </div>
        )
    }

    function showContacts(contacts) {
        contacts = [...contacts].sort((a, b) => a.position - b.position);
        return contacts.map(contact => <> {showContact(contact)} </>);
    }

    function showLeftContent() {
        return (
            <div className={'contacts-left-content'}>
                {contactInfo.contacts && contactInfo.contacts.length && showContacts(contactInfo.contacts)}
                {contactInfo.work_time && showWorkTime()}

                <div className={'contacts-tooltip-text'}>
                    <div className={'page-text'}>{GetTextWithBoldParts(
                        'Вы можете отправить нам письмо, заполнив форму ниже:'
                    )}</div>
                </div>
                <ContactsFormComponent/>
            </div>
        )
    }

    function showRightContent() {
        return (
            <div className={'contacts-right-content'}>
                <div className={'page-white-text contacts-address-format'}>{GetTextWithBoldParts(
                    'Офис компании располагается по адресу:<br>' +
                    '<b>'+ contactInfo.address +'</b>'
                )}</div>
                <ContactsMapComponent
                    address={contactInfo.address}
                    workTime={contactInfo.work_time}
                    gps={contactInfo.gps}
                />
            </div>
        )
    }

    function showMiddleContent() {
        return (
            <div className={'contacts-middle-content'}>
                {showLeftContent()}
                {showRightContent()}
            </div>
        )
    }

    function showMobileContent() {
        return (
            <div className={'contacts-mobile-content'}>

                {contactInfo.contacts && contactInfo.contacts.length && showContacts(contactInfo.contacts)}
                {contactInfo.work_time && showWorkTime()}

                <div className={'contacts-address-text'}>
                    <div className={'page-white-text contacts-address-format'}>{GetTextWithBoldParts(
                        'Офис компании располагается по адресу: '  +
                        '<b>'+ contactInfo.address ? contactInfo.address : '' +'</b>'
                    )}</div>
                    <ContactsMapComponent
                        address={contactInfo.address}
                        workTime={contactInfo.work_time}
                        gps={contactInfo.gps}
                    />
                </div>

                <div className={'contacts-tooltip-text'}>
                    <div className={'page-text'}>{GetTextWithBoldParts(
                        'Вы можете отправить нам письмо, заполнив форму ниже:'
                    )}</div>
                </div>
                <ContactsFormComponent/>
            </div>
        )
    }

    return (
        <div className={'contacts'}>
            {showDust()}
            <div className={'contacts-content'}>
                <BreadCrumbsComponent
                    breadCrumbs={breadCrumbs}
                />
                <p className={'page-title'}>Контакты</p>
                <p className={'page-subtitle'}>ООО «Эверест снабжение»</p>
                {showMiddleContent()}
                {showMobileContent()}
            </div>
        </div>
    )
}

ContactsContentComponent.propTypes = {}

export default ContactsContentComponent;
