// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts_list {
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 6000;
    min-width: 230px;
}

.contact_item {
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.contacts_item_icon_image {
    width: 25px;
    position: relative;
    top: 6px;
}

.contact_item_icon {
    display: inline-block;
    margin-right: 10px;
    width: 25px;
}

.contact_item_text {
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', sans-serif;
    font-size: 19px;
    cursor: pointer;
    z-index: 6000;
}

@media (max-width: 1060px) {
    .contacts_list {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/TopBar/ContactsList/ContactsList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,qBAAqB;IACrB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,uEAAuE;IACvE,eAAe;IACf,eAAe;IACf,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".contacts_list {\n    float: right;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-end;\n    z-index: 6000;\n    min-width: 230px;\n}\n\n.contact_item {\n    margin-left: auto;\n    margin-right: auto;\n    display: block;\n}\n\n\n.contacts_item_icon_image {\n    width: 25px;\n    position: relative;\n    top: 6px;\n}\n\n.contact_item_icon {\n    display: inline-block;\n    margin-right: 10px;\n    width: 25px;\n}\n\n.contact_item_text {\n    display: inline-block;\n    font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', sans-serif;\n    font-size: 19px;\n    cursor: pointer;\n    z-index: 6000;\n}\n\n@media (max-width: 1060px) {\n    .contacts_list {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
