import React from "react";
import './AboutLinkButton.css'
import GrayArrowButtonComponent from "../../../../instances/buttons/GrayArrowButton/GrayArrowButtonComponent";
import BluePulseButtonComponent from "../../../../instances/buttons/BluePulseButton/BluePulseButtonComponent";
import DefaultBluePulseButtonComponent
    from "../../../../instances/buttons/DefaultBluePulseButton/DefaultBluePulseButtonComponent";

function AboutLinkButtonComponent(props) {
    return (
        <div className={'about-link-button'}>
            <DefaultBluePulseButtonComponent
                title="О КОМПАНИИ"
                route={'./about'}
                customWidth={'300px'}
            />
        </div>

    )
}

AboutLinkButtonComponent.propTypes = {

}

export default AboutLinkButtonComponent;