import React from "react";

export function GetTextWithBoldParts(text) {
    if(text) {
        const html = text.replace(/<b>(.*?)<\/b>/g, '<b>$1</b>');
        return <div dangerouslySetInnerHTML={{__html: html}}/>;
    } else {
        return (
            <></>
        )
    }
}

export function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    } else {
        return text;
    }
}


export function checkStringSuffix(str) {
    // Проверяем, оканчивается ли строка на '.bin'
    if (str.endsWith('.bin')) {
        return false;
    }
    // Проверяем, оканчивается ли строка на 'null'
    else if (str.endsWith('null')) {
        return false;
    }
    // Проверяем, оканчивается ли строка на 'undefined'
    else if (str.endsWith('undefined')) {
        return false;
    }
    // Если ни один из условий не выполнен, возвращаем true
    else {
        return true;
    }
}


export function getHrefFromAction(action, text) {

    if(action === 'tel')
        return `tel:${text}`;

    if(action === 'mail')
        return `mailto:${text}`;

    if (action.startsWith('link:')) {
        return action.slice(5);
    }

    return undefined

}
