import './CatalogCategoriesContent.css'
import React from "react";
import CatalogCategoriesListComponent from "../CatalogCategoriesList/CatalogCategoriesListComponent";
import module from "../../../constants/CatalogConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";

function CatalogCategoriesContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);

    function showDust() {
        return <img src={'/assets/catalog-link/dust.svg'} className={'catalog-dust'} />;
    }

    function showTitle() {
        return (
            <p className={'catalog-title'}>КАТАЛОГ</p>
        )
    }

    return (
        <div className={'catalog-content'}>
            {showDust()}
            <div className={'catalog-bread-crumbs'}>
                <BreadCrumbsComponent
                    breadCrumbs={breadCrumbs}
                />
            </div>
            <div className={'catalog-content-list'}>
                {showTitle()}
                <CatalogCategoriesListComponent/>
            </div>
        </div>
    )
}

CatalogCategoriesContentComponent.propTypes = {

}

export default CatalogCategoriesContentComponent;
