import React from "react";
import './TeaserBuyButton.css'
import RedArrowButtonComponent from "../../../../instances/trash/RedArrowButton/RedArrowButtonComponent";
import BluePulseButtonComponent from "../../../../instances/buttons/BluePulseButton/BluePulseButtonComponent";


function TeaserBuyButtonComponent(props) {
    return (
        <div className={'teaser-buy-button'}>
            <BluePulseButtonComponent
                title="Подробнее"
            ></BluePulseButtonComponent>
        </div>

    )
}

TeaserBuyButtonComponent.propTypes = {

}

export default TeaserBuyButtonComponent;