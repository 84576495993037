import commonModule from "./CommonConstants";

let MENU_LIST_ARRAY = [
    {
        title: 'Главная',
        type: 'route',
        route: '/'
    }, {
        title: 'О компании',
        type: 'route',
        route: '/about'
    }, {
        title: 'Каталог',
        type: 'menu',
        menu: [
            //TODO from database CATALOG
            {
                title: 'Электроинструмент',
                type: 'route',
                route: '/catalog/0f1882e0-33c9-45aa-b92e-4f4814a23250'
            }, {
                title: 'Ручной инструмент',
                type: 'route',
                route: '/catalog/f0ceb429-03de-4803-96e1-f773eb4dba40'
            }, {
                title: 'Станки',
                type: 'route',
                route: '/catalog/0db5bdf4-2d77-43cd-a254-42e23b1a6817'
            }, {
                title: 'Садовая техника',
                type: 'route',
                route: '/catalog/ac05b1ed-bec3-412d-9273-1a6d1db22660'
            }, {
                title: 'Садовая техника',
                type: 'route',
                route: '/catalog/ac05b1ed-bec3-412d-9273-1a6d1db22660'
            }
        ]
    }, {
        title: 'Клиентам',
        type: 'menu',
        menu: [
            //TODO from database KLIENTAM
            {
                title: 'Акции',
                route: '/actions'
            }, {
                title: 'Доставка и оплата',
                type: 'route',
                route: '/delivery'
            }, {
                title: 'Сервис',
                type: 'route',
                route: '/services'
            }, {
                title: 'Контакты',
                type: 'route',
                route: '/contacts'
            }
        ]
    }, {
        //TODO from database DOWNLOAD PRICE LIST
        title: 'Прайс-лист',
        download: commonModule.SITE_URL + '/media/price-list',
        downloadTitle: 'Прайс-лист',
        style: {color: '#039AD2'}
    }
];


let CONTACTS_LIST_ARRAY = [
    {
        icon: '/assets/topbar/contacts/phone.svg',
        text: '+7 981 830 38 33',
        action: 'tel'
    }, {
        icon: '/assets/topbar/contacts/mail.svg',
        text: 'zakoz@everestsnab.ru',
        action: {}
    }, {
        icon: '/assets/topbar/contacts/telegram.svg',
        text: '@everestsnab',
        action: {}
    }
]


let module = {
    MENU_LIST_ARRAY: MENU_LIST_ARRAY,
    CONTACTS_LIST_ARRAY: CONTACTS_LIST_ARRAY
}

export default module;
