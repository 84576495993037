import React, {useEffect} from 'react';
import './DeliveryContent.css';
import '../../common/Common.css';
import module from "../../../constants/DeliveryConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import { GetTextWithBoldParts } from "../../../utils/TextFunctions";
import {getObject} from "../../../utils/RestApi";


function DeliveryContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);
    let [contentItems, setContentItems] = React.useState([])

    useEffect(() => {
        getObject('/api/delivery_info', setContentItems)
    }, [])

    function showDust() {
        return <img src={'/assets/delivery/dust.svg'} className={'right-top-dust'} />;
    }

    // Сортировка массива по position
    const sortedContentItems = [...contentItems].sort((a, b) => a.position - b.position);

    return (
        <div className={'delivery'}>
            {showDust()}
            <div className={'delivery-content'}>
                <BreadCrumbsComponent breadCrumbs={breadCrumbs} />
                <p className={'page-title'}>ОПЛАТА И ДОСТАВКА</p>
                {sortedContentItems.map(item => (
                    <React.Fragment key={item.position}>
                        {item.subtitle && <p className={'page-subtitle'}>{item.subtitle}</p>}
                        <div className={'page-white-text'}>{GetTextWithBoldParts(item.text)}</div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

DeliveryContentComponent.propTypes = {};

export default DeliveryContentComponent;
