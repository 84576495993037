import React, { useState, useEffect, useRef } from 'react';
import './CatalogCategoriesList.css';
import module from "../../../constants/CatalogConstants";
import CategoryGrayArrowButtonComponent from "../../../instances/buttons/CategoryGrayArrowButton/CategoryGrayArrowButtonComponent";
import {getArray} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";
import DefaultBluePulseButtonComponent
    from "../../../instances/buttons/DefaultBluePulseButton/DefaultBluePulseButtonComponent";

function CatalogCategoriesListComponent(props) {
    let [categories, setCategories] = useState([]);
    const [startAnimation, setStartAnimation] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        getArray('/api/catalog_categories', setCategories)
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setStartAnimation(true);
                observer.disconnect(); // Отключаем наблюдение после первого появления
            }
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    function showCategoryImage(category) {
        return (
            <img
                className={'catalog-category-image'}
                src={commonModule.SITE_URL + '/media/catalog-categories/' + category.image}
                onError={(e) => { e.target.src = commonModule.SITE_URL + '/media/catalog-categories/default.svg'; }}
            />
        );
    }

    function showCategoryContent(category) {
        let title = category.title.toUpperCase();
        return (
            <div className={'catalog-category-content'}>
                <p className={'catalog-category-title'}>{title}</p>
                <DefaultBluePulseButtonComponent
                    title={'ПЕРЕЙТИ'}
                    route={'/catalog/' + category.id}
                />
            </div>
        );
    }

    function showCategory(category, index) {
        const animationClass = startAnimation? (index % 2 === 0? 'slideInLeft' : 'slideInRight') : '';
        return (
            <div className={`catalog-category`} style={{animationName: animationClass}}>
                {showCategoryContent(category)}
                {showCategoryImage(category)}
            </div>
        );
    }

    function showCategories(categories) {
        return categories.map((category, index) => <>{showCategory(category, index)}</>);
    }

    return (
        <div className={'catalog-categories'} ref={containerRef}>
            {showCategories(categories)}
        </div>
    );
}

export default CatalogCategoriesListComponent;
