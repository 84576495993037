import TopBarComponent from "../components/common/TopBar/TopBarComponent";
import FooterComponent from "../components/common/Footer/FooterComponent";
import ActionsContentComponent from "../components/actions/ActionsContent/ActionsContentComponent";


function ActionsPage() {
    return (
        <div className="App">
            <header className="App-header">
                <TopBarComponent
                    morphScrollY={0}
                />
                <ActionsContentComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default ActionsPage;
