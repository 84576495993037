let CATEGORIES_LIST_ARRAY = [
    //TODO from database CATALOG
    {
        title: 'Электроинструмент',
        id: '0f1882e0-33c9-45aa-b92e-4f4814a23250'
    }, {
        title: 'Ручной инструмент',
        id: 'f0ceb429-03de-4803-96e1-f773eb4dba40'
    }, {
        title: 'Станки',
        id: '0db5bdf4-2d77-43cd-a254-42e23b1a6817'
    }, {
        title: 'Садовая техника',
        id: 'ac05b1ed-bec3-412d-9273-1a6d1db22660'
    }
]

let BRANDS_LIST_OBJECT = {
    '0f1882e0-33c9-45aa-b92e-4f4814a23250': [
        {
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        }, {
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        },{
            image: '95c2ce4e-d6e5-4498-8348-b7e12378581e.svg',
            file: '3d90ed33-d984-473e-945c-17752a3a64ed.pdf'
        }
    ]
}

let BREAD_CRUMBS_LIST_ARRAY = [
    {
        title: 'Главная страница',
        route: '/'
    }, {
        title: 'Каталог',
        route: undefined
    }
]

let BREAD_CRUMBS_CATEGORY_LIST_ARRAY = [
    {
        title: 'Главная страница',
        route: '/'
    }, {
        title: 'Каталог',
        route: '/catalog'
    }, {
        title: 'None',
        route: undefined
    }
]


let module = {
    CATEGORIES_LIST_ARRAY: CATEGORIES_LIST_ARRAY,
    BREAD_CRUMBS_LIST_ARRAY: BREAD_CRUMBS_LIST_ARRAY,
    BREAD_CRUMBS_CATEGORY_LIST_ARRAY: BREAD_CRUMBS_CATEGORY_LIST_ARRAY,
    BRANDS_LIST_OBJECT: BRANDS_LIST_OBJECT
}

export default module;
