import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import './SideBar.css';
import SideBarElementComponent from "./SideBarElement/SideBarElementComponent"; // Предполагается, что CSS находится в Sidebar.css
import module from '../../../constants/SideBarConstants'
import {getArray, getObject} from "../../../utils/RestApi";

const SideBarComponent = (props) => {
    const [isSidebarActive, setSidebarActive] = useState(false);
    let [items, setItems] = React.useState([]);

    useEffect(() => {
        getArray('/api/mobile_menu', setItems)
    }, [])

    const toggleSidebar = () => {
        setSidebarActive(!isSidebarActive);
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 27) {
            toggleSidebar();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current &&!ref.current.contains(event.target)) {
                setSidebarActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    function getReversedList(list) {
        const reversedList = [];
        for (let i = list.length - 1; i >= 0; i--) {
            reversedList.push(list[i]);
        }
        return reversedList;
    }

    function offSidebar() {
        if(isSidebarActive === true) {
            setSidebarActive(false);
        }
    }

    function showElements(elements) {
        return elements.map((value, index) => {
            return (
                <li className={`sidebar-item ${isSidebarActive ? 'active' : ''}`}>
                    <SideBarElementComponent
                        title={value.title}
                        animationOffset={0}
                        handler={()=> {
                            offSidebar()
                        }}
                        style={value.style}
                        route={value.route ? value.route : 'undefined'}
                        download={value.download ? value.download : null}
                        downloadTitle = {value.downloadTitle ? value.downloadTitle : null}
                    />
                </li>
            )
        })
    }

    return (
        <div className={'sidebar-outer'} ref={ref}>
            <div className={`nav-right visible-xs ${isSidebarActive ? 'active' : ''}`} onClick={toggleSidebar}>
                <div className={`sidebar-button ${isSidebarActive ? 'active' : ''}`} id="btn">
                    <div className="bar top"></div>
                    <div className="bar middle"></div>
                    <div className="bar bottom"></div>
                </div>
            </div>
            <main className={`${isSidebarActive ? 'move-to-left' : ''}`}>
                <nav>
                    <div className={`nav-right hidden-xs ${isSidebarActive ? 'active' : ''}`} onClick={toggleSidebar}>
                        <div className={`sidebar-button ${isSidebarActive ? 'active' : ''}`} id="btn">
                            <div className="bar top"></div>
                            <div className="bar middle"></div>
                            <div className="bar bottom"></div>
                        </div>
                    </div>
                </nav>
                <a href="https://codepen.io/tonkec/" className="ua" target="_blank">
                    <i className="fa fa-user"></i>
                </a>
            </main>
            <div className={`sidebar ${isSidebarActive ? 'active' : ''}`}>
                <ul className="sidebar-list">
                    {showElements(items)}
                </ul>
            </div>
        </div>
    );
};

SideBarComponent.propTypes = {
}

export default SideBarComponent;
