import React, {useEffect} from "react";
import './ContactsList.css'
import module from "../../../../constants/TopBarConstants";
import {getArray, getObject} from "../../../../utils/RestApi";
import commonModule from "../../../../constants/CommonConstants";
import {getHrefFromAction} from "../../../../utils/TextFunctions";

function ContactsListComponent(props) {

    let [contacts, setContacts] = React.useState([]);
    useEffect(() => {
        getArray('/api/contacts', setContacts)
    }, [])




    function showContact(contact) {
        return (
            <div className={'contact_item'}>
                <div className={'contact_item_icon'}>
                    <img
                        className={'contacts_item_icon_image'}
                        src={commonModule.SITE_URL + '/media/contacts/'+contact.icon}
                    />
                </div>
                <a
                    href={getHrefFromAction(contact.action, contact.text)}
                    className={'contact_item_text'}
                    style={{ textDecoration: 'none', color: 'white' }}
                    target={contact.action.startsWith('link:') ? '_blank' : undefined}
                >{contact.text}</a>
            </div>
        )
    }

    function showContacts(contacts) {
        contacts = [...contacts].sort((a, b) => a.position - b.position);
        console.log(contacts)
        return contacts.map(contact => <> {showContact(contact)} </>);
    }

    return (
        <div className={'contacts_list'}>
            {showContacts(contacts)}
        </div>
    )
}

ContactsListComponent.propTypes = {

}

export default ContactsListComponent;
