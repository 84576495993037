// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area-property {
    width: 100%;
}

.text-area-property-title {
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    font-weight: bold;
    line-height: 0px;
}

.text-area-property-input {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 183px;
    min-height: 183px;
    background-color: #656770;
    border-radius: 6px;
    border: 1px solid #9B9B9B;
    color: white;
    font-size: 16px;
    outline: none; /* Убирает черную обводку */
    padding-left: 10px; /* Добавляет отступ вокруг текста */
    padding-right: -10px;
    padding-top: 8px;
    z-index: 1000;
    cursor: text;
}
`, "",{"version":3,"sources":["webpack://./src/instances/text-properties/TextAreaProperty/TextAreaProperty.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,aAAa,EAAE,2BAA2B;IAC1C,kBAAkB,EAAE,mCAAmC;IACvD,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".text-area-property {\n    width: 100%;\n}\n\n.text-area-property-title {\n    font-family: \"Fira Sans\", sans-serif;\n    font-size: 14px;\n    color: #FFFFFF;\n    text-align: left;\n    font-weight: bold;\n    line-height: 0px;\n}\n\n.text-area-property-input {\n    width: 100%;\n    max-width: 100%;\n    min-width: 100%;\n    height: 183px;\n    min-height: 183px;\n    background-color: #656770;\n    border-radius: 6px;\n    border: 1px solid #9B9B9B;\n    color: white;\n    font-size: 16px;\n    outline: none; /* Убирает черную обводку */\n    padding-left: 10px; /* Добавляет отступ вокруг текста */\n    padding-right: -10px;\n    padding-top: 8px;\n    z-index: 1000;\n    cursor: text;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
