export const downloadFromUrl = async (url, fileName, mimeType) => {
  try {
    // Проверяем, является ли URL валидным
    const result = new URL(url);
    if (!result.protocol || !result.host) {
      throw new Error('Невалидный URL');
    }

    // Открываем URL в новой вкладке
    window.open(url, '_blank');

    // Логируем успешное открытие URL
    console.log(`URL "${url}" открыт в новой вкладке`);

  } catch (error) {
    console.error('Ошибка при попытке открыть URL:', error);
  }
};