import React, {useEffect} from 'react';
import './AboutContent.css'
import '../../common/Common.css'
import module from "../../../constants/AboutConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import {GetTextWithBoldParts} from "../../../utils/TextFunctions";
import RequisitesDownloadWidgetComponent from "../RequisitesDownloadWidget/RequisitesDownloadWidgetComponent";
import {getArray, getObject} from "../../../utils/RestApi";

function AboutContentComponent(props) {
    //TODO download

    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);
    let [aboutInfo, setAboutInfo] = React.useState({})

    useEffect(() => {
        getObject('/api/about_info', setAboutInfo)
    }, [])

    function showDust() {
        return <img src={'/assets/catalog-link/dust.svg'} className={'left-dust'} />;
    }

    if(aboutInfo && aboutInfo.titleFile && aboutInfo.requisites && aboutInfo.titleDate) {
        return (
            <div className={'about'}>
                {showDust()}
                <div className={'about-content'}>
                    <BreadCrumbsComponent
                        breadCrumbs={breadCrumbs}
                    />
                    <p className={'page-title'}>О НАС</p>
                    <p className={'page-text'}>{aboutInfo.description}</p>
                    <p className={'page-subtitle'}>Реквизиты</p>
                    <div className={'page-text-requisites'}>{GetTextWithBoldParts(aboutInfo.requisites)}</div>
                    <RequisitesDownloadWidgetComponent
                        title={aboutInfo.titleFile}
                        date={aboutInfo.titleDate}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={'about'}>
            {showDust()}
            <div className={'about-content'}>
                <BreadCrumbsComponent
                    breadCrumbs={breadCrumbs}
                />
            </div>
        </div>
    )


}

AboutContentComponent.propTypes = {}

export default AboutContentComponent;
