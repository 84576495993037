let SERVICES_LIST_ARRAY = [
    {
        image: '8d3bb272-a529-46ab-8cc4-19b7bbdd3024.svg',
        title: 'МЕТАЛООБРАБОТКА',
        description: 'Наши профессиональные металообрабатывающие инструменты обеспечивают точность и эффективность ваших работ, делая процесс обработки металла легким и безопасным.'
    }
]

let BREAD_CRUMBS_LIST_ARRAY = [
    {
        title: 'Главная страница',
        route: '/'
    }, {
        title: 'Сервис',
        route: undefined
    }
]


let module = {
    SERVICES_LIST_ARRAY: SERVICES_LIST_ARRAY,
    BREAD_CRUMBS_LIST_ARRAY: BREAD_CRUMBS_LIST_ARRAY
}

export default module;
