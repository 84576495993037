import TopBarComponent from "../components/common/TopBar/TopBarComponent";
import FooterComponent from "../components/common/Footer/FooterComponent";
import ServiceContentComponent from "../components/service/ServiceContent/ServiceContentComponent";


function ServicesPage() {
    return (
        <div className="App">
            <header className="App-header">
                <TopBarComponent
                    morphScrollY={0}
                />
                <ServiceContentComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default ServicesPage;
