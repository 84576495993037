let BREAD_CRUMBS_LIST_ARRAY = [
    {
        title: 'Главная страница',
        route: '/'
    }, {
        title: 'Контакты',
        route: undefined
    }
]


let module = {
    BREAD_CRUMBS_LIST_ARRAY: BREAD_CRUMBS_LIST_ARRAY
}

export default module;
