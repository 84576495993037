import React, {useEffect, useState} from "react";
import '../../common/Common.css'
import DisablePulseButtonComponent from "../../../instances/buttons/DisablePulseButton/DisablePulseButtonComponent";
import './CatalogBrandsListContent.css'
import module from "../../../constants/CatalogConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import {useParams} from "react-router-dom";
import BluePulseButtonComponent from "../../../instances/buttons/BluePulseButton/BluePulseButtonComponent";
import {getArray, getObject} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";
import {checkStringSuffix} from "../../../utils/TextFunctions";

function CatalogBrandsListContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_CATEGORY_LIST_ARRAY);
    let [categories, setCategories] = useState([]);
    let [brands, setBrands] = React.useState([]);

    const { id } = useParams();
    let title = findTitleById(id);

    useEffect(() => {
        getArray('/api/catalog_categories', setCategories)
        getArray('/api/catalog_brands?id=' + id, setBrands)
    }, [])

    function findTitleById(id) {
        const foundCategory = categories.find(category => category.id === id);
        if (foundCategory) {
            return foundCategory.title;
        } else {
            return null; // Или любое другое значение, указывающее на отсутствие совпадения
        }
    }

    function showBrand(brand) {
        return (
            <div className={'catalog-brand'}>
                <div className={(brand && brand.file) ? 'catalog-brand-image-box' : 'catalog-brand-image-box empty'}>
                    <div className={'catalog-brand-image-outer'}>
                        <img
                            className={'catalog-brand-image-inner'}
                            src={commonModule.SITE_URL + '/media/' + brand.image}
                            onError={(e) => {
                                e.target.src = commonModule.SITE_URL + '/media/catalog-brands/default.svg';
                            }}
                        />
                    </div>
                </div>

                {brand && brand.file &&
                    <div className={'catalog-brand-button'}>

                        <div className={'two-words-catalog-brand-button'}>
                            <BluePulseButtonComponent
                                title={'СКАЧАТЬ КАТАЛОГ'}
                                download={commonModule.SITE_URL + '/media/' + brand.file}
                                downloadTitle={brand.file}
                            />
                        </div>

                        <div className={'one-word-catalog-brand-button'}>
                            <BluePulseButtonComponent
                                title={'КАТАЛОГ'}
                                download={commonModule.SITE_URL + '/media/' + brand.file}
                                downloadTitle={brand.file}
                            />
                        </div>
                    </div>
                }

                {
                    brand && !brand.file &&
                    <div className={'catalog-brand-button'}>

                        <div className={'two-words-catalog-brand-button'}>
                            <DisablePulseButtonComponent
                                title={'СКАЧАТЬ КАТАЛОГ'}
                                download={commonModule.SITE_URL + '/media/' + brand.file}
                                downloadTitle={brand.file}
                            />
                        </div>

                        <div className={'one-word-catalog-brand-button'}>
                            <DisablePulseButtonComponent
                                title={'КАТАЛОГ'}
                                download={commonModule.SITE_URL + '/media/' + brand.file}
                                downloadTitle={brand.file}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    function showBrands(brands) {
        return brands.map(brand => <> {showBrand(brand)} </>);
    }

    function showDust() {
        return <img src={'/assets/catalog-brands/dust.svg'} className={'left-bigger-dust'}/>;
    }

    function showTitle() {
        return (
            <p className={'catalog-brands-title'}>{title}</p>
        )
    }

    if (title) {
        return (
            <div className={'catalog-brands'}>
                {showDust()}
                <div className={'catalog-brands-content'}>
                    <BreadCrumbsComponent
                        breadCrumbs={breadCrumbs}
                        lastElementTitle={title}
                    />
                    {showTitle()}
                    <div className={'catalog-brands-list'}>
                        {brands && brands[id] && showBrands(brands[id])}
                    </div>
                </div>
            </div>
        )
    } else {
        return (

            <div className={'catalog-brands'}>
                {showDust()}
            </div>

        )
    }


}

CatalogBrandsListContentComponent.propTypes = {

}

export default CatalogBrandsListContentComponent
