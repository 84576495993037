let ADVANTAGES_LIST_ARRAY = [
    {
        icon: '/assets/teaser/advantages/delivery-time.svg',
        text: 'Обработка заказа и доставка от 1 дня'
    }, {
        icon: '/assets/teaser/advantages/payment-delay.svg',
        text: 'Отсрочка оплаты для постоянных клиентов'
    }, {
        icon: '/assets/teaser/advantages/professional-selection.svg',
        text: 'Профессиональный подбор инструментов и оборудования'
    }, {
        icon: '/assets/teaser/advantages/free-delivery.svg',
        text: 'Бесплатная доставка от 50000 рублей',
        className: 'advantage-second-text'
    }
]


let module = {
    ADVANTAGES_LIST_ARRAY: ADVANTAGES_LIST_ARRAY
}

export default module;
