import TopBarComponent from "../components/common/TopBar/TopBarComponent";
import TeaserComponent from "../components/home/Teaser/TeaserComponent";
import AboutLinkComponent from "../components/home/AboutLink/AboutLinkComponent";
import CatalogLinkComponent from "../components/home/CatalogLink/CatalogLinkComponent";
import ContactsLinkComponent from "../components/home/ContactsLink/ContactsLinkComponent";
import FooterComponent from "../components/common/Footer/FooterComponent";
import ModalBluePulseButtonComponent from "../instances/buttons/ModalBluePulseButton/ModalBluePulseButtonComponent";
import React from "react";
import PartnersLinkComponent from "../components/home/PartnersLink/PartnersLinkComponent";

function HomePage() {
    return (
        <div className="App">
            <header className="App-header">
                <TopBarComponent
                    morphScrollY={0}
                />
                <TeaserComponent/>
                <AboutLinkComponent/>
                <CatalogLinkComponent/>
                <PartnersLinkComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default HomePage;
