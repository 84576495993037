import React from 'react';
import './CatalogLink.css'

import CatalogCategoriesListComponent
    from "../../catalog/CatalogCategoriesList/CatalogCategoriesListComponent";

function CatalogLinkComponent(props) {

    function showDust() {
        return <img src={'/assets/catalog-link/dust.svg'} className={'catalog-dust'} />;
    }

    function showTitle() {
        return (
            <p className={'catalog-title'}>КАТАЛОГ</p>
        )
    }

    function showTransitionDecor() {
        return (

            <div className={'catalog-link-transition-decor-outer'}>
                <img className={'catalog-link-transition-decor'} src={'/assets/catalog-link/transition-decor.svg'}/>
            </div>
        )
    }

    return (
        <div className={'catalog-link'}>
            {showDust()}
            {showTransitionDecor()}
            {showTitle()}
            <CatalogCategoriesListComponent/>
        </div>
    )
}

CatalogLinkComponent.propTypes = {

}

export default CatalogLinkComponent;
