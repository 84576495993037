import React, { useState, useEffect } from 'react';
import './ModalFormToast.css';
import PropTypes from "prop-types";

function ModalFormToastComponent(props) {
    const [isActive, setIsActive] = useState(false);
    const [isInvisible, setIsInvisible] = useState(true); // Новое состояние для управления прозрачностью

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsActive(true);
        }, 100); // Задержка перед отображением

        return () => clearTimeout(timer);
    }, [props.reactor]);

    useEffect(() => {
        if (isActive) {
            const timer = setTimeout(() => {
                setIsActive(false);
            }, props.duration);

            return () => clearTimeout(timer);
        }
    }, [isActive, props.duration]);

    // Установка начального состояния invisibility и таймаут для изменения его после 10 секунд
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsInvisible(false);
        }, 10000); // 10 секунд перед появлением тоста

        return () => clearTimeout(timer);
    }, []);

    const closeToast = () => {
        setIsActive(false);
    };

    return (
        <div className={`toast ${isActive ? 'active' : ''} ${isInvisible ? 'toast-invisible' : ''}`}> {/* Добавлено условие для класса toast-invisible */}
            <div className="toast-content">
                <i className="fas fa-solid fa-check check">
                    <img src={'./assets/other/white_ok.svg'} className={'toast-ok'}></img>
                </i>
                <div className="message">
                    <span className="text text-1">{props.title}</span>
                    <span className="text text-2">{props.description}</span>
                </div>
            </div>
            <img className="fa-solid fa-xmark close" src={'./assets/other/notification_cancel.svg'} onClick={closeToast}></img>
            <div className={`${isActive ? 'progress active' : ''}`}></div>
        </div>
    );
};

ModalFormToastComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    openHandler: PropTypes.func,
    reactor: PropTypes.bool
};

export default ModalFormToastComponent;