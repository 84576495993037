// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-text-property {
    width: 100%;
}

.default-text-property-title {
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    font-weight: bold;
    line-height: 0px;
}

.default-text-property-input {
    width: 100%;
    height: 36px;
    background-color: #656770;
    border-radius: 6px;
    border: 1px solid #9B9B9B;
    color: white;
    font-size: 16px;
    outline: none; /* Убирает черную обводку */
    padding-left: 10px; /* Добавляет отступ вокруг текста */
    padding-right: -10px;
    margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/instances/text-properties/DefaultTextProperty/DefaultTextProperty.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,aAAa,EAAE,2BAA2B;IAC1C,kBAAkB,EAAE,mCAAmC;IACvD,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".default-text-property {\n    width: 100%;\n}\n\n.default-text-property-title {\n    font-family: \"Fira Sans\", sans-serif;\n    font-size: 14px;\n    color: #FFFFFF;\n    text-align: left;\n    font-weight: bold;\n    line-height: 0px;\n}\n\n.default-text-property-input {\n    width: 100%;\n    height: 36px;\n    background-color: #656770;\n    border-radius: 6px;\n    border: 1px solid #9B9B9B;\n    color: white;\n    font-size: 16px;\n    outline: none; /* Убирает черную обводку */\n    padding-left: 10px; /* Добавляет отступ вокруг текста */\n    padding-right: -10px;\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
