import React from "react";
import './MobileGrayArrowButton.css'
import PropTypes from "prop-types";


function MobileGrayArrowButtonComponent(props) {

    const navigateToPage = () => {
        window.location.href = props.route;
    };

    function showTitle() {
        return (
            <span className={'red-arrow-button-title'}>{props.title}</span>
        )
    }

    function showArrow() {
        return (
            <div className={'mobile-gray-arrow-button-arrow'}>
                <img src={'/assets/buttons/arrow.svg'} className={'mobile-gray-arrow-button-arrow-image'}></img>
            </div>
        )
    }

    function getCustomHorizontalPadding() {
        if(props.customHorizontalPadding) {
            return props.customHorizontalPadding;
        } else {
            return 40
        }
    }

    return (
        <div className={'mobile-gray-arrow-button'} onClick={navigateToPage}>
            <a href="#">
                <p>
                    <span className="bg"> </span>
                    <span className="text">{props.title}</span>
                    {showArrow()}
                </p>
            </a>
        </div>
    )
}

MobileGrayArrowButtonComponent.propTypes = {
    title: PropTypes.string,
    customHorizontalPadding: PropTypes.number,
    route: PropTypes.string
}

export default MobileGrayArrowButtonComponent;
