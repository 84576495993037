import React, {useEffect, useState} from 'react';
import './AboutLink.css';
import AboutLinkButtonComponent from "./AboutLinkButton/AboutLinkButtonComponent";
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import MobileGrayArrowButtonComponent
    from "../../../instances/buttons/MoblieGrayArrowButton/MobileGrayArrowButtonComponent";
import MobileAboutLinkButtonComponent from "./MobileAboutLinkButton/MobileAboutLinkButtonComponent";
import {getObject} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";

function ColoredText({ text, color }) {
    const start = text.indexOf('<color>');
    const end = text.indexOf('</color>');

    if (start === -1 || end === -1) {
        return <p>{text}</p>;
    }

    const beforeColor = text.substring(0, start);
    const coloredText = text.substring(start + '<color>'.length, end);
    const afterColor = text.substring(end + '</color>'.length);

    return (
        <p className={'about-link-title'}>
            {beforeColor}
            <span style={{ color: color }}>{coloredText}</span>
            {afterColor}
        </p>
    );
}


let constantAboutLinkInfo = {
    'title': "ЛУЧШИЕ ИНСТРУМЕНТЫ ПО <color>ДОСТУПНОЙ ЦЕНЕ</color>",
    'description': 'Эверест Снабжение предлагает в своем ассортименте популярные модели садовой техники и электроинструмента. Мы нацелены на потребителя в первую очередь оценивающего идеальное соотношение цены и качества продукта.'
}

function AboutLinkComponent(props) {
    const { scrollY } = useViewportScroll();
    const scrollRange = 800; // Примерное значение диапазона прокрутки, которое вы хотите использовать для анимации
    let [aboutLinkInfo, setAboutLinkInfo] = useState(constantAboutLinkInfo)

    useEffect(() => {
        getObject('/api/about_link_info', setAboutLinkInfo)
    }, [])

    const scale = useTransform(scrollY, [200, scrollRange], [0, 1]);
    const rotate = useTransform(scrollY, [200, scrollRange], [45, 0]);

    function showDust() {
        return <img
            src={'/assets/about-link/dust.svg'}
            className={'about-link-dust'}
        />;
    }

    function showDrill() {
        return (

            <>
                <div className={'about-link-drill'}>
                    <motion.img
                        src={commonModule.SITE_URL + '/media/'+aboutLinkInfo.image}
                        alt="Responsive Image"
                        style={{
                            scale,
                            rotate,
                            originX: "center",
                            originY: "center",
                        }}
                    />
                </div>

                <img
                    src={commonModule.SITE_URL + '/media/'+aboutLinkInfo.image}
                    alt="Responsive Image"
                    className={'mobile-about-link-drill'}
                />
            </>

        );
    }

    function showTitle() {
        return <ColoredText text={aboutLinkInfo.title} color="#01A1DD"/>;
    }

    function showDescription() {
        return (
            <p className={'about-link-description'}>{aboutLinkInfo.description}</p>
        );
    }

    function showButton() {
        return (
            <div className={'about-link-button-outer'}>
                <AboutLinkButtonComponent />
            </div>
        )
    }

    function showMobileButton() {
        return (
            <div className={'about-link-button-mobile-outer'}>
                <MobileAboutLinkButtonComponent />
            </div>
        )
    }

    function showContent() {
        //TODO LINK
        return (
            <div className={'about-link-content'}>
                {showTitle()}
                {showDescription()}
                {showButton()}
            </div>
        );
    }

    return (
        <div className="about-link">
            {showDust()}
            <div className={"about-link-inner"}>
                {showContent()}
                {showDrill()}
            </div>
            {showMobileButton()}
        </div>
    );
}

export default AboutLinkComponent;
