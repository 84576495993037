import React, {useEffect, useState} from 'react';
import './Footer.css'
import module from "../../../constants/FooterConstants";
import OpacityButtonComponent from "../../../instances/buttons/OpacityButton/OpacityButtonComponent";
import ModalFormComponent from "../../../instances/other/ModalForm/ModalFormComponent";
import {getArray, getObject} from "../../../utils/RestApi";
import {getHrefFromAction} from "../../../utils/TextFunctions";
import commonModule from "../../../constants/CommonConstants";


function ColoredText({ text, color }) {
    const start = text.indexOf('<color>');
    const end = text.indexOf('</color>');

    if (start === -1 || end === -1) {
        return <p>{text}</p>;
    }

    const beforeColor = text.substring(0, start);
    const coloredText = text.substring(start + '<color>'.length, end);
    const afterColor = text.substring(end + '</color>'.length);

    return (
        <p className={'footer-end-line-title'}>
            {beforeColor}
            <span style={{ color: color }}>{coloredText}</span>
            {afterColor}
        </p>
    );
}

function FooterComponent(props) {

    let [lineInfo, setLineInfo] = React.useState(module.LINE_INFO_LIST_ARRAY);
    let [columnInfo, setColumnInfo] = React.useState(module.COLUMN_INFO_LIST_ARRAY);
    let [isModalEnabled, setIsModalEnabled] = useState(false)
    let [footerInfo, setFooterInfo] = React.useState([])
    let [footerInstance, setFooterInstance] = React.useState([])
    let [contactInfo, setContactInfo] = React.useState({})

    useEffect(() => {
        getArray('/api/footer_info', setFooterInfo)
        getObject('/api/contact_info', setContactInfo)
        getObject('/api/footer_instance', setFooterInstance)
    }, [])


    function showModalForm() {
        setIsModalEnabled(true)
    }

    function closeModalForm() {
        setIsModalEnabled(false)
    }


    const navigateToPage = (route) => {
        window.location.href = route;
    };

    function showEndLineDescription() {
        return (
            <p className={'footer-end-line-description'}>{footerInstance.description}</p>
        )
    }

    function showEndLineTitle() {
        //TODO CLICKABLE
        return (
            <ColoredText
                text={footerInstance.title}
                color={'#01A1DD'}
            />
        )
    }

    function showColumnInfoElement(columnInfoElement) {
        return (
            <p className={'footer-column-info-element-title'}
               onClick={()=>{navigateToPage(columnInfoElement.route)}}
            >{columnInfoElement.title}</p>
        )
    }

    function showLineInfoElement(lineInfoElement) {
        return (
            <div className={'footer-line-info-element'}
                 onClick={()=>{navigateToPage(lineInfoElement.route)}}
            >
                <img className={'footer-line-info-element-image'} src={lineInfoElement.image}/>
                <p className={'footer-line-info-element-title'}>{lineInfoElement.title}</p>
            </div>
        )
    }

    function showColumnInfo(columnInfo) {
        return columnInfo.map(columnInfoElement => <> {showColumnInfoElement(columnInfoElement)} </>);
    }

    function showLineInfo(lineInfo) {
        return lineInfo.map(lineInfoElement => <> {showLineInfoElement(lineInfoElement)} </>);
    }

    function showLogo() {
        return (
            <img className={'footer-logo'} src={'/assets/footer/russian-everest-logo.svg'}/>
        )
    }

    function showLeftInfoElement(icon, text) {
        return (

            <>
                <p className={'footer-left-info-element footer-left-info-element-a'}>
                    <img className={'footer-left-info-element-a-icon'} src={icon}/>
                    {text}
                </p>
                <div style={{height: '5px'}}></div>
            </>
        )
    }

    function showContactInfoHeader(icon, text, action) {
        return (
            <div className={'footer-left-info-element'}>
                <img className={'footer-left-info-element-icon'} src={commonModule.SITE_URL + '/media/contacts/' + icon}/>
                <a
                    className={'footer-left-info-element-a'}
                    href={getHrefFromAction(action, text)}
                    target={action.startsWith('link:') ? '_blank' : undefined}
                >{text}</a>
            </div>
        )
    }

    function showContactInfoElement(contact) {
        return (
            <div className={'footer-left-info-element-full'}>
                {showContactInfoHeader(contact.icon, contact.text, contact.action)}
                {contact.description && <p className={'footer-left-info-element-call-text'}>{contact.description}</p>}
            </div>
        )
    }

    function showContactInfoList(contacts) {
        console.log(contacts)
        contacts = [...contacts].sort((a, b) => a.position - b.position);
        return contacts.map(contact => <> {showContactInfoElement(contact)} </>);
    }

    function showAddressLinkButton() {
        //TODO LINK
        return (
            <div className={'left-footer-address-link-button'}>
                <OpacityButtonComponent
                    title="Контакты и адреса"
                    route={'./contacts'}
                />
            </div>
        )
    }

    function showFeedbackButton() {
        return (
            <a className={'footer-feedback-button'} onClick={() => {showModalForm()}}>Форма обратной связи</a>
        )
    }

    function showRightFooterContent() {
        return (
            <div className={'right-footer-content'}>
                <div className={'right-footer-line-and-column'}>
                    <div className={'footer-line-info'}>
                        {showLineInfo(lineInfo)}
                    </div>

                    <div className={'footer-column-info'}>
                        {showColumnInfo(columnInfo)}
                    </div>

                </div>
            </div>
        )
    }

    function showLeftFooterContent() {
        return (
            <div className={'left-footer-content'}>
                {showLogo()}
                {contactInfo && showLeftInfoElement('/assets/footer/map-icon.svg', 'Адрес: ' + contactInfo.address)}
                <div className={'dynamic-offset'}/>
                {contactInfo && showLeftInfoElement('/assets/footer/time-icon.svg', 'График работы: ' + contactInfo.work_time)}
                {footerInfo && footerInfo.length && showContactInfoList(footerInfo)}
                {showFeedbackButton()}
            </div>
        )
    }

    function showEndLineContent() {
        if(footerInstance && footerInstance.title && footerInstance.description)
            return (
                <>
                    {showEndLineTitle()}
                    {showEndLineDescription()}
                </>
            )
        else {
            return (
                <></>
            )
        }
    }

    function showFooterContent() {
        return (
            <>
                {showLeftFooterContent()}
                {showRightFooterContent()}
            </>
        )
    }

    return (
        <>
            <div className={'footer'}>
                <div className={'footer-content'}>
                    {showFooterContent()}
                </div>
            </div>
            <div className={'footer-end-line'}>
                <div className={'footer-end-line-content'}>
                    {showEndLineContent()}
                </div>
            </div>
            <ModalFormComponent
                active={isModalEnabled}
                handleClose={() => {closeModalForm()}}
            />
        </>
    )
}

FooterComponent.propTypes = {}
export default FooterComponent;
