import React from "react";
import './BluePulseButton.css'
import PropTypes from "prop-types";
import {downloadFromUrl} from "../../../utils/DownloadFunctions";


function BluePulseButtonComponent(props) {


    const navigateToPage = () => {
        window.location.href = props.route;
    };

    function showTitle() {
        return (
            <span className={'red-arrow-button-title'}>{props.title}</span>
        )
    }

    function showArrow() {
        return (
            <div>
                <img src={'/assets/buttons/arrow.svg'}></img>
            </div>
        )
    }

    function getCustomHorizontalPadding() {
        if(props.customHorizontalPadding) {
            return props.customHorizontalPadding;
        } else {
            return 40
        }
    }

    function onClick() {
        if(props.download) {
            downloadFromUrl(props.download, props.downloadTitle, 'application/octet-stream')
        } else {
            if(props.handler) {
                props.handler()
            } else {
                navigateToPage()
            }
        }
    }

    return (
        <div
            className={'pulse-button'}
            style = {props.customWidth ? { width : props.customWidth } : undefined}>
            <a
                href={!props.handler ? "#" : undefined}
                onClick={() => {onClick()}}
            >
                <p>
                    <span className="bg"> </span>
                    <span className="text">{props.title}</span>
                </p>
            </a>
        </div>
    )
}

BluePulseButtonComponent.propTypes = {
    title: PropTypes.string,
    customHorizontalPadding: PropTypes.number,
    route: PropTypes.string,
    download: PropTypes.string,
    downloadTitle: PropTypes.string,
    customWidth: PropTypes.number,
    handler: PropTypes.func
}

export default BluePulseButtonComponent;
