// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-form {
    margin-top: 20px;
}

.contacts-form-button {
    margin-left: -30px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    top: 50px;
    padding-bottom: 150px;
    pointer-events: none;
}

.contacts-form-text-property {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}


@media (max-width: 1180px) {
    .contacts-form-text-property {
        width: 98.5%;
    }
}


@media (max-width: 850px) {
    .contacts-form-text-property {
        width: 85%;
        margin-left: 6vw;
        margin-top: 10px;
    }

    .contacts-form-button {
        margin-left: 2vw;
    }
}

@media (max-width: 650px) {
    .pulse-button {
        width: 300px;
    }
}

.ymaps-2-1-79-map {
    width: 100%;
}

@media (max-width: 550px) {
    .contacts-form-button {
        margin-left: auto;
        margin-right: auto;
    }


    .pulse-button {
        width: 240px;
        margin-right: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/contacts/ContactsForm/ContactsForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;;;AAGA;IACI;QACI,YAAY;IAChB;AACJ;;;AAGA;IACI;QACI,UAAU;QACV,gBAAgB;QAChB,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;;;IAGA;QACI,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".contacts-form {\n    margin-top: 20px;\n}\n\n.contacts-form-button {\n    margin-left: -30px;\n    width: fit-content;\n    height: fit-content;\n    position: relative;\n    top: 50px;\n    padding-bottom: 150px;\n    pointer-events: none;\n}\n\n.contacts-form-text-property {\n    float: left;\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n\n@media (max-width: 1180px) {\n    .contacts-form-text-property {\n        width: 98.5%;\n    }\n}\n\n\n@media (max-width: 850px) {\n    .contacts-form-text-property {\n        width: 85%;\n        margin-left: 6vw;\n        margin-top: 10px;\n    }\n\n    .contacts-form-button {\n        margin-left: 2vw;\n    }\n}\n\n@media (max-width: 650px) {\n    .pulse-button {\n        width: 300px;\n    }\n}\n\n.ymaps-2-1-79-map {\n    width: 100%;\n}\n\n@media (max-width: 550px) {\n    .contacts-form-button {\n        margin-left: auto;\n        margin-right: auto;\n    }\n\n\n    .pulse-button {\n        width: 240px;\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
