import React, {useState} from 'react';
import './DefaultTextProperty.css'
import PropTypes from "prop-types";

function DefaultTextProperty(props) {
    let [text, setText] = useState('')

    function handleOnChange(value) {
        setText(value)
        props.handler(value)
    }


    function formatPhoneNumber(phoneNumber) {
        // Проверяем, является ли строка телефонным номером в формате '+7...'
        if (phoneNumber.length > 2 && phoneNumber.startsWith('+7') && phoneNumber.length < 13) {
            // Удаляем '+7' из начала строки
            let number = phoneNumber.slice(2);

            // Если длина номера больше 12 символов, возвращаем исходную строку
            if (number.length > 12) {
                return phoneNumber;
            }

            // Форматируем номер
            let formattedNumber = '';
            for (let i = 0; i < number.length; i++) {
                if (i > 0 && i % 3 === 0 && i < 7) {
                    formattedNumber += ' ';
                } else if (i > 0 && i % 2 === 0 && i > 7) {
                    formattedNumber += ' ';
                }
                formattedNumber += number[i];
            }

            // Возвращаем отформатированный номер
            return '+7 ' + formattedNumber;
        } else {
            // Если строка не является телефонным номером в формате '+7...', возвращаем исходную строку
            return phoneNumber;
        }
    }

    function getValidatedPhone(value) {
        let textLocal = value.replace(/[^\d\+]/g, '');
        let result = ''

        if(textLocal[0] === '7') {
            result = '+' + text
        } else
        if(textLocal[0] !== '+' && textLocal[1] !== '7') {
            result = '+7' + textLocal
        } else {
            if(textLocal[0] === '+' && textLocal[1] !== '7') {
                let returned = '+7' + textLocal
                if(returned[2] === '+' && returned.length > 2) {
                    returned = returned.substr(0, 2)
                }
                result = returned
            } else {
                if(textLocal[0] === '+' && textLocal[1] === '7') {
                    result = textLocal
                }
            }
        }
        result = formatPhoneNumber(result)
        return result
    }


    return (
        <div className={'default-text-property'}>
            <p className={'default-text-property-title'}>{props.title}</p>
            <input
                className={'default-text-property-input'}
                onChange={(event) => {
                    handleOnChange(event.target.value)
                }}
                value={props.isPhone ? getValidatedPhone(text) : text}
            />
        </div>
    )
}

DefaultTextProperty.propTypes = {
    title: PropTypes.string,
    handler: PropTypes.func,
    isPhone: PropTypes.bool
}

export default DefaultTextProperty;
