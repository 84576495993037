import React, {useState} from "react";
import BluePulseButtonComponent from "../BluePulseButton/BluePulseButtonComponent";
import ModalFormComponent from "../../other/ModalForm/ModalFormComponent";


function ModalBluePulseButtonComponent(props) {


    let [isModalEnabled, setIsModalEnabled] = useState(false)

    function showModalForm() {
        setIsModalEnabled(true)
    }

    function closeModalForm() {
        setIsModalEnabled(false)
    }

    return (
        <>
            <BluePulseButtonComponent
                title={'СВЯЗАТЬСЯ С НАМИ'}
                handler={() => {showModalForm()}}
            />
            <ModalFormComponent
                active={isModalEnabled}
                handleClose={() => {closeModalForm()}}
            />
        </>

    )
}

ModalBluePulseButtonComponent.propTypes = {

}

export default ModalBluePulseButtonComponent
