import React from 'react';
import PropTypes from "prop-types";
import './RequisitesDownloadWidget.css'
import {downloadFromUrl} from "../../../utils/DownloadFunctions";

function RequisitesDownloadWidgetComponent(props) {


    function showIcon() {
        return (
            <img className={'requisites-download-widget-icon'} src={'/assets/about/pdf-icon.svg'} />
        )
    }

    function showContent() {
        return (
            <div className={'requisites-download-widget-content'}>
                <p className={'requisites-download-widget-title'}>{props.title}</p>
                <p className={'requisites-download-widget-description'}>{props.date}</p>
            </div>
        )
    }

    function showDownloadButton() {
        return (
            <img className={'requisites-download-widget-button'} src={'/assets/about/download-icon.svg'} />
        )
    }

    if(props.title && props.date) {
        return (
            <div
                className={'requisites-download-widget'}
                onClick={() => {
                    downloadFromUrl('./media/requisites.pdf', 'Реквизиты', 'application/pdf');
                }}
            >
                {showIcon()}
                {showContent()}
                {showDownloadButton()}
            </div>
        )
    } else {
        return <></>
    }

}

RequisitesDownloadWidgetComponent.propTypes = {
    title: PropTypes.string,
    date: PropTypes.string
}

export default RequisitesDownloadWidgetComponent
