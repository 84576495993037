import React, {useEffect, useState} from 'react';
import DefaultTextProperty from "../../../instances/text-properties/DefaultTextProperty/DefaultTextProperty";
import BluePulseButtonComponent from "../../../instances/buttons/BluePulseButton/BluePulseButtonComponent";
import TextAreaProperty from "../../../instances/text-properties/TextAreaProperty/TextAreaProperty";
import emailjs from "@emailjs/browser";
import ModalFormToastComponent from "../../../instances/other/ModalFormToast/ModalFormToastComponent";
import './ContactsForm.css'
import PhoneInput from "react-phone-number-input/input";

function ContactsFormComponent(props) {
    let [mail, setMail] = React.useState("");
    let [contact, setPhone] = React.useState("+7");
    let [text, setText] = React.useState("");
    const [firstTime, setFirstTime] = useState(true)
    const [duration, setDuration] = useState(0)
    const [reactor, setReactor] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            var css = '' +
                '@media (max-width: 650px) {\n' +
                '    .pulse-button {\n' +
                '        width: 300px;\n' +
                '    }\n' +
                '}' +
                '@media (max-width: 550px) {\n' +
                '    .pulse-button {\n' +
                '        width: 240px;\n' +
                '        margin-right: 0;\n' +
                '    }\n' +
                '}' ;
            var style = document.createElement('style');

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            document.getElementsByTagName('head')[0].appendChild(style);
        }, 1000)
    }, []);

    const sendForm = () => {
        setFirstTime(false)
        if(firstTime && mail && contact !== '+7' && text) {
            sendEmail()
            setTimeout(() => {
                window.location.reload()
            },3000)
        }
    }

    const sendEmail = () => {
        let formParams = {
            mail: mail,
            text: text,
            contact: contact
        }

        emailjs.send('autoeverestsnab', 'default_template', formParams, 'VGQzcLCZHjebs1hSu')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.log('FAILED...', error);
            });

        setDuration(5000)
        setReactor(!reactor)
    };

    function showRedSubscriptionValue(value) {
        if (!firstTime && !value) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./assets/other/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showRedSubscriptionPhoneValue(value) {
        if (!firstTime && (value === '+7' || !value || value === '+' || value === '7')) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./assets/other/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <div className={'contacts-form'}>
            <div className={'contacts-form-text-property'}>
                <DefaultTextProperty
                    title={'Электронная почта'}
                    handler={(mail) => {
                        setMail(mail)
                    }}
                />
                {showRedSubscriptionValue(mail)}
            </div>

            <div className={'contacts-form-text-property'}>
                <DefaultTextProperty
                    title={'Телефон'}
                    handler={(contact) => {
                        setPhone(contact)
                    }}
                    isPhone={true}
                />
                {showRedSubscriptionPhoneValue(contact)}
            </div>

            <div className={'contacts-form-text-property'}>
                <TextAreaProperty
                    title={'Текст'}
                    handler={(text) => {
                        setText(text)
                    }}
                />
                {showRedSubscriptionValue(text)}
            </div>

            <div className={'contacts-form-button'}>
                <BluePulseButtonComponent
                    title={'Отправить'}
                    handler={() => {sendForm()}}
                />
            </div>
            <ModalFormToastComponent
                title={'Отправлено'}
                description="Дождитесь ответа в ближайшее время"
                duration={duration}
                reactor={reactor}
            />

        </div>
    )

}

ContactsFormComponent.propTypes = {}
export default ContactsFormComponent;
