import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import './TopBar.css';
import TopBarMenuListComponent from "./TopBarMenuList/TopBarMenuListComponent";
import ContactsListComponent from "./ContactsList/ContactsListComponent";
import SideBarComponent from "../SideBar/SideBarComponent";
import ModalBluePulseButtonComponent
    from "../../../instances/buttons/ModalBluePulseButton/ModalBluePulseButtonComponent";

function TopBarComponent(props) {
    const [isScrolled, setIsScrolled] = useState(false);

    // Функция для обработки изменения положения скролла
    const handleScroll = () => {
        if (window.scrollY > props.morphScrollY) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const navigateToPage = (route) => {
        window.location.href = route;
    };

    // Добавляем обработчик событий прокрутки к окну при монтировании компонента
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Удаляем обработчик событий при размонтировании компонента
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function showLogo() {
        return (
            <img
                className={'logo'}
                src={'/assets/topbar/russian-everest-logo.svg'}
                width={'166px'}
                onClick={()=>{navigateToPage('/')}}
            />
        );
    }

    function showScrolledUnusedArea() {
        return (
            <div className={'scrolling-unused-background'}/>
        )
    }

    function showFeedbackButton() {
        return (
            <div className={'top-bar-feedback-button'}>
                <ModalBluePulseButtonComponent/>
            </div>
        )
    }

    function showRightElement() {
        if(isScrolled) {
            return showFeedbackButton()
        } else {
            return (
                <ContactsListComponent/>
            )
        }
    }



    return (
        <div className={isScrolled ? 'scrolling-background' : 'no-scrolling-background'}>
            <div className={isScrolled ? 'scrolled-topbar' : 'topbar'}>
                {showLogo()}

                <div className={'top-bar-elements'}>
                    <TopBarMenuListComponent/>
                    {showRightElement()}
                </div>

                <SideBarComponent/>

            </div>
        </div>
    )

}

TopBarComponent.propTypes = {
    morphScrollY: PropTypes.number,
};

export default TopBarComponent;
