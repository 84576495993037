import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from "./pages/AboutPage";
import DeliveryPage from "./pages/DeliveryPage";
import ContactsPage from "./pages/ContactsPage";
import CatalogCategoriesPage from "./pages/CatalogCategoriesPage";
import CatalogBrandsPage from "./pages/CatalogBrandsPage";
import ServicesPage from "./pages/ServicesPage";
import ActionsPage from "./pages/ActionsPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage/>}/>
                <Route exact path="/about" element={<AboutPage/>}/>
                <Route exact path="/delivery" element={<DeliveryPage/>}/>
                <Route exact path="/contacts" element={<ContactsPage/>}/>
                <Route exact path="/catalog" element={<CatalogCategoriesPage/>}/>
                <Route exact path="/services" element={<ServicesPage/>}/>
                <Route exact path="/actions" element={<ActionsPage/>}/>
                <Route exact path="/catalog/:id" element={<CatalogBrandsPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
