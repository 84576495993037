import React, {useEffect, useState} from 'react';
import './ContactsLink.css'
import {getObject} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";

function ContactsLinkComponent(props) {

    let [contactsLinkInfo, setContactsLinkInfo] = useState({})

    useEffect(() => {
        getObject('/api/contacts_link_info', setContactsLinkInfo)
    }, [])

    let route = './contacts'

    const navigateToPage = () => {
        window.location.href = route;
    };

    function showContactsTitle() {
        return (
            <div className={'contacts-link-title'}>
                <div className="contacts-link-title-text-outer">
                    <p className={"contacts-link-title-text-inner"}>КОНТАКТЫ</p>
                </div>
            </div>
        )
    }

    function showContactsImage() {
        return (
            <div className="contacts-link-image-outer">
                <div className={'contacts-link-background'}></div>
                <img className={"contacts-link-image-inner"} src={commonModule.SITE_URL + '/media/' + contactsLinkInfo.image}/>
            </div>
        )
    }

    function releaseMouseEnter() {
        let background = document.getElementsByClassName('contacts-link-background')[0]
        let imageOuter = document.getElementsByClassName('contacts-link-image-outer')[0]
        let title = document.getElementsByClassName('contacts-link-title')[0]

        background.style.opacity = '0'
        imageOuter.style.width = '60%'
        title.style.width = '40%'
    }

    function releaseMouseLeave() {
        let background = document.getElementsByClassName('contacts-link-background')[0]
        let imageOuter = document.getElementsByClassName('contacts-link-image-outer')[0]
        let title = document.getElementsByClassName('contacts-link-title')[0]

        background.style.opacity = '0.49'
        imageOuter.style.width = '50%'
        title.style.width = '50%'
    }

    return (
        <div
            className={'contacts-link'}
            onMouseEnter={() => releaseMouseEnter()}
            onMouseLeave={() => releaseMouseLeave()}
            onClick={navigateToPage}
        >
            <div className="contacts-link-content">
                {showContactsTitle()}
                {showContactsImage()}
            </div>
        </div>

    )
}

ContactsLinkComponent.propTypes = {}

export default ContactsLinkComponent;
