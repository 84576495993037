import React from "react";
import './MobileAboutLinkButton.css'
import MobileGrayArrowButtonComponent
    from "../../../../instances/buttons/MoblieGrayArrowButton/MobileGrayArrowButtonComponent";
import DefaultBluePulseButtonComponent
    from "../../../../instances/buttons/DefaultBluePulseButton/DefaultBluePulseButtonComponent";

function MobileAboutLinkButtonComponent(props) {
    return (
        <div className={'mobile-about-link-button'}>


            <DefaultBluePulseButtonComponent
                title="О КОМПАНИИ"
                route={'./about'}
            />
        </div>

    )
}

MobileAboutLinkButtonComponent.propTypes = {

}

export default MobileAboutLinkButtonComponent;
