import React from 'react';
import './BreadCrumbs.css'
import PropTypes from "prop-types";

function BreadCrumbsComponent(props) {

    let breadCrumbs = props.breadCrumbs

    function navigateToPage(route) {
        window.location.href = route;
    }

    if(props.lastElementTitle) {
        breadCrumbs[breadCrumbs.length - 1].title = props.lastElementTitle
    }

    function showBreadCrumb(breadCrumb) {
        return (
            <>
                <a
                    className={breadCrumb.route ? 'bread-crumb-active-title' : 'bread-crumb-disactive-title'}
                    onClick={() => {
                        if(breadCrumb.route) {
                            navigateToPage(breadCrumb.route);
                        }
                    }}
                >
                    {breadCrumb.title}
                </a>
                {
                    breadCrumb.route &&
                    <img
                        className={'bread-crumb-icon'}
                        src={'/assets/other/bread-crumbs-arrow.svg'}
                    />
                }
            </>
        )
    }

    return (
        <div className={'bread-crumbs'}>
            <img className={'bread-crumb-home-icon'} src={'/assets/other/bread-crumbs-home.svg'}/>
            {breadCrumbs.map(breadCrumb => <> {showBreadCrumb(breadCrumb)} </>)}
        </div>
    )
}

BreadCrumbsComponent.propTypes = {
    breadCrumbs: PropTypes.array,
    lastElementTitle: PropTypes.string
}

export default BreadCrumbsComponent
