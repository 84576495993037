import commonModule from "./CommonConstants";

let MENU_LIST_ARRAY = [
    {
        title: 'Главная',
        type: 'route',
        route: '/'
    }, {
        title: 'О компании',
        type: 'route',
        route: '/about'
    }, {
        title: 'Каталог',
        type: 'menu',
        route: 'catalog'
    }, {
        title: 'Акции',
        route: '/actions'
    }, {
        title: 'Доставка и оплата',
        type: 'route',
        route: '/delivery'
    }, {
        title: 'Сервис',
        type: 'route',
        route: '/services'
    }, {
        title: 'Контакты',
        type: 'route',
        route: '/contacts'
    }, {
        //TODO from database DOWNLOAD PRICE LIST
        title: 'Прайс-лист',
        download: commonModule.SITE_URL + '/media/price-list',
        downloadTitle: 'Прайс-лист',
        style: {color: '#039AD2'}
    }
];


let CONTACTS_LIST_ARRAY = [
    {
        icon: '/assets/topbar/contacts/phone.svg',
        text: '+7 981 830 38 33',
        action: 'tel'
    }, {
        icon: '/assets/topbar/contacts/mail.svg',
        text: 'zakoz@everestsnab.ru',
        action: {}
    }, {
        icon: '/assets/topbar/contacts/telegram.svg',
        text: '@everestsnab',
        action: {}
    }
]


let module = {
    MENU_LIST_ARRAY: MENU_LIST_ARRAY,
    CONTACTS_LIST_ARRAY: CONTACTS_LIST_ARRAY
}

export default module;
