let NUMBER_MARKETS_SPB = 1
let PHONE = '+7 (981) 830-38 33'


let LINE_INFO_LIST_ARRAY = [
    //TODO from database CATALOG
    {
        title: 'Каталог',
        type: 'route',
        route: '/catalog',
        image: '/assets/footer/catalog-icon.svg',
    }, {
        title: 'Сервис',
        type: 'route',
        route: '/services',
        image: '/assets/footer/service-icon.svg',
    }, {
        title: 'Акции',
        type: 'route',
        route: '/actions',
        image: '/assets/footer/actions-icon.svg',
    },
]


let COLUMN_INFO_LIST_ARRAY = [
    //TODO from database CATALOG
    {
        title: 'Доставка и оплата',
        type: 'route',
        route: '/delivery'
    }, {
        title: 'Контакты',
        type: 'route',
        route: '/contacts'
    }, {
        title: 'О компании',
        type: 'route',
        route: '/about',
    },
]


let module = {
    NUMBER_MARKETS_SPB: NUMBER_MARKETS_SPB,
    PHONE: PHONE,
    LINE_INFO_LIST_ARRAY: LINE_INFO_LIST_ARRAY,
    COLUMN_INFO_LIST_ARRAY: COLUMN_INFO_LIST_ARRAY
}

export default module;
