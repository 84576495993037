import TopBarComponent from "../components/common/TopBar/TopBarComponent";
import FooterComponent from "../components/common/Footer/FooterComponent";
import CatalogCategoriesContentComponent from "../components/catalog/CatalogCategoriesContent/CatalogCategoriesContentComponent";


function CatalogCategoriesPage() {
    return (
        <div className="App">
            <header className="App-header">
                <TopBarComponent
                    morphScrollY={0}
                />
                <CatalogCategoriesContentComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default CatalogCategoriesPage;
