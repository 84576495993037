import React from 'react';
import './TextAreaProperty.css'
import PropTypes from "prop-types";

function TextAreaProperty(props) {

    function handleOnChange(value) {
        props.handler(value)
    }

    return (
        <div className={'text-area-property'}>
            <p className={'text-area-property-title'}>{props.title}</p>
            <textarea
                className={'text-area-property-input'}
                onChange={(event) => {
                    handleOnChange(event.target.value)
                }}
            />
        </div>
    )
}

TextAreaProperty.propTypes = {
    title: PropTypes.string,
    handler: PropTypes.func
}

export default TextAreaProperty;
