import React, {useEffect} from "react";
import '../../common/Common.css'
import './ServiceContent.css'
import module from "../../../constants/ServiceConstants";
import BreadCrumbsComponent from "../../../instances/other/BreadCrumbs/BreadCrumbsComponent";
import {useParams} from "react-router-dom";
import {GetTextWithBoldParts, truncateText} from "../../../utils/TextFunctions";
import {getArray, getObject} from "../../../utils/RestApi";
import commonModule from "../../../constants/CommonConstants";

function ServiceContentComponent(props) {
    let [breadCrumbs, setBreadCrumbs] = React.useState(module.BREAD_CRUMBS_LIST_ARRAY);
    let [services, setServices] = React.useState([]);

    useEffect(() => {
        getArray('/api/services', setServices)
    }, [])

    const { id } = useParams();

    function showService(service) {
        return (
            <div className={'service'}>
                <div className={'service-image-outer'}>
                    <img
                        className={'service-image-inner'}
                        src={commonModule.SITE_URL + '/media/services/'+service.image}
                        onError={(e) => { e.target.src = commonModule.SITE_URL + '/media/services/default.svg'; }}
                    />
                </div>
                <div className={'service-text'}>
                    <p className={'service-title'}>{service.title}</p>

                    <div className={'service-description'}>
                        {GetTextWithBoldParts(service.description)}
                    </div>
                </div>
            </div>
        )
    }

    function showServices(services) {
        return services.map(service => <> {showService(service)} </>);
    }

    function showDust() {
        return <img src={'/assets/services/dust.svg'} className={'left-bigger-dust'} />;
    }

    function showTitle() {
        return (
            <p className={'services-title'}>СЕРВИС</p>
        )
    }

    return (
        <div className={'services'}>
            {showDust()}
            <div className={'services-content'}>
                <BreadCrumbsComponent
                    breadCrumbs={breadCrumbs}
                />
                {showTitle()}
                <div className={'services-list'}>
                    {showServices(services)}
                </div>
            </div>
        </div>
    )


}

ServiceContentComponent.propTypes = {

}

export default ServiceContentComponent
