import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {downloadFromUrl} from "../../../../utils/DownloadFunctions";

function TopBarElementComponent(props) {

    const [isSelected, setIsSelected] = useState(false)
    const [isOnceVisibled, setIsOnceVisibled] = useState(false)

    useEffect(() => {
        if(isOnceVisibled) {
            const element = document.getElementById(props.target_id);
            element.style.animationName = 'fadeInUp'
        }
    }, [isOnceVisibled])

    const navigateToPage = (route) => {
        window.location.href = route;
    };



    function handleClick() {
        if(props.download && props.downloadTitle) {
            downloadFromUrl(props.download, props.downloadTitle, 'application/octet-stream')
        } else {
            if(props.route !== 'undefined') {
                navigateToPage(props.route)
            }
            else {

            }
        }
    }


    return (
        <a
            className="sidebar-anchor"
            onClick={() => {
                handleClick()
            }}
            style={props.style ? props.style : {}}
        >{props.title}</a>
    )
}

TopBarElementComponent.propTypes = {
    title: PropTypes.string,
    target_id: PropTypes.string,
    animationOffset: PropTypes.number,
    handler: PropTypes.any,
    style: PropTypes.any,
    route: PropTypes.string,
    download: Promise.string
}

export default TopBarElementComponent

