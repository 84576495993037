import React from "react";
import './AdvantagesList.css'
import module from "../../../../constants/HomeConstants";

function AdvantagesListComponent(props) {

    let [advantages, setAdvantages] = React.useState(module.ADVANTAGES_LIST_ARRAY);

    function showAdvantage(advantage) {
        return (
            <div className={'advantage'}>
                <div className={'advantage-background'}/>
                <img className={'advantage-icon'} src={advantage.icon}/>
                <p className={advantage.className ? advantage.className : 'advantage-text'}>{advantage.text}</p>
            </div>
        )
    }

    function showAdvantages(advantages) {
        return advantages.map(advantage => <> {showAdvantage(advantage)} </>);
    }

    return (
        <div className={'advantages'}>
            {showAdvantages(advantages)}
        </div>
    )
}

AdvantagesListComponent.propTypes = {

}

export default AdvantagesListComponent;
